
    <nav class="fixed w-full z-50 bg-gradient-to-b from-gray-900">
        <div class="z-20 w-full relative">
            <div class="max-w-screen-2xl mx-auto px-8 md:px-16 z-30 relative">
                <div class="flex justify-between h-16">
                    <div class="flex justify-between w-full">
                        <div class="flex-shrink-0 flex items-center">
                            <a routerLink="/{{ 'LANG-CODE' | translate }}" (click)="scrollToTop()">
                                
                                <img class="
                                h-10 md:h-12 w-auto" src="/assets/images/lawisapartments.png" alt="Lawis Apartments">
                                <!--
                                <img class="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg" alt="Workflow">
                            -->
                            </a>
                        </div>
                        <div class="flex items-center">
                            <a class="btn hidden sm:block" href='{{ "RESERVATION-LINK" | translate }}' target="_blank">
                                {{ "RESERVATION-TITLE" | translate }}
                            </a>
                            <a *ngIf="activeLocale == 'sk'" class="inline-flex items-center justify-center p-2.5 text-gray-300 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 active:ring-0 cursor-pointer mx-2" (click)="changeLang('en')">
                                EN
                            </a>

                            <a *ngIf="activeLocale == 'en'" class="inline-flex items-center justify-center p-2.5 text-gray-300 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 active:ring-0 cursor-pointer mx-2" (click)="changeLang('sk')">
                                SK
                            </a>
                            <!-- Mobile menu button -->
                            <a class="inline-flex items-center justify-center p-2.5 text-gray-300 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 active:ring-0 cursor-pointer z-50" aria-controls="mobile-menu" aria-expanded="false" (click)="toggleMenu();">
                                <span class="sr-only">Open main menu</span>
                                <!--
                                    Icon when menu is closed.

                                    Heroicon name: outline/menu

                                    Menu open: "hidden", Menu closed: "block"
                                -->
                                <svg class="block h-6 w-6 mt-0.5" [ngClass]="menuActive ? 'hidden' : ''" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                                <!--
                                  Icon when menu is open.

                                  Heroicon name: outline/x

                                  Menu open: "block", Menu closed: "hidden"
                                -->
                                <svg class="h-6 w-6 mt-0.5" [ngClass]="menuActive ? '' : 'hidden'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="hidden md:ml-6 md:space-x-2">
                            <app-main-nav></app-main-nav>
                        </div>
                    </div>
                    <!--
                    <div class="flex">
                        <div class="hidden md:ml-6 md:space-x-4">
                            <a class="nav-btn" [routerLink]="'MENU-LINK-6' | translate" [routerLinkActive]="'active'">{{ "MENU-TITLE-6" | translate }}</a>
                            <div class="nav-border inline-block">
                                <a href="tel:+421900111444">
                                    <div class="inline-block align-middle mr-1">
                                        <fa-icon [icon]="['fas', 'phone']"></fa-icon>
                                    </div>

                                    +421 900 111 444
                                </a>
                            </div>
                        </div>
                    </div>
                -->
                </div>
            </div>
        </div>
    </nav>
        <!-- Mobile menu, show/hide based on menu state. -->
    <div [@collapse]="menuActive ? 'open' : 'closed'" class="z-40 fixed right-0 top-0 w-3/4 sm:w-1/2 bg-gray-900 shadow-md pt-20" id="mobile-menu">
        <div class="pt-0 px-12 pb-6 space-y-1">
            <!-- Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
            <app-main-nav></app-main-nav>
            <!--
            <div class="grid grid-cols-2">
                <a class="nav-btn text-center col-span-2 sm:col-span-1 sm:mr-4 block" [routerLink]="'MENU-LINK-6' | translate" [routerLinkActive]="'active'">
                    {{ "MENU-TITLE-6" | translate }}
                </a>
                <a href="tel:+421900111444" class="nav-border col-span-2 sm:col-span-1 sm:ml-4 flex block text-center">
                    <div class="inline-block align-middle mr-1">
                        <fa-icon [icon]="['fas', 'phone']"></fa-icon>
                    </div>

                    +421 900 111 444
                </a>
            </div>
        -->
        </div>
    </div>
<main id="topBlock" class="bg-gray-900 text-gray-400">
    <div id="top" class="top-0 absolute"></div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
    <app-alert></app-alert>
</main>
<footer class="bg-gray-900">
    <div class="relative max-w-screen-2xl mx-auto lg:mb-12">
        <div class="grid grid-cols-12 relative z-10">
            <div class="col-span-12 md:col-span-6 pb-7 overflow-hidden">
                <div class="relative z-0 overflow-hidden">
                    <!--
                    <iframe src="https://snazzymaps.com/embed/311679" width="100%" height="500px" style="border:none;"></iframe>
                -->
                    <div class="overflow-hidden relative" style="margin-left: calc((100% - 768px)/2);max-width:768px;width:768px;">
                        <!--
                        <iframe width="640px" height="500px" style="transform: scale(1.3)" [src]="sanitizer.bypassSecurityTrustResourceUrl(mapsUrl)"></iframe>
                    -->
                        <img [src]="sanitizer.bypassSecurityTrustResourceUrl(mapsUrl)" style="transform: scale(1.35);">
                    </div>
                <!--
                    <img class="h-auto w-full mx-auto" src="/assets/images/map.jpg" alt="Lawis Apartments">
                -->
                </div>
            
                <div class="-mt-28 relative z-10 text-center">
                    <a class="btn btn-outline mx-auto" href="https://www.google.com/maps/dir//''/@49.0564482,20.2090575,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x473e3b119bd6eadf:0x220084e3ac8a35b5!2m2!1d20.2790972!2d49.0563569!3e0" target="_blank">
                        {{ "PLAN-ROUTE" | translate }}
                    </a>
                </div>
            </div>
            <div class="col-span-12 md:col-span-6 pr-8 md:pr-16 pt-28 md:pb-20">
                <img class="h-24 w-auto mx-auto" src="/assets/images/logo.svg" alt="Lawis Apartments">
                <p class="pt-16 pb-5 pl-8 md:pl-16 text-gray-300 text-justify">
                    {{ "ABOUT-LAWIS" | translate }}
                </p>
            </div>
        </div>
    </div>
    <div id="contactBlock" class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto pt-8 lg:pt-6 lg:py-6 px-8 lg:px-16">
        <div class="grid grid-cols-12 justify-center">
            <div class="col-span-12 lg:col-span-4 lg:col-start-2 lg:pr-8 pt-10 lg:pl-8">
                <div class="mx-auto">
                    <div class="mb-10 lg:mt-10">
                        <img class="h-20 w-auto mb-12" src="/assets/images/lawisapartments.png" alt="Lawis Apartments">
                        <dl class="my-8 space-y-6">
                            <dt><span class="sr-only">Telefón</span></dt>
                            <dd class="flex text-lg footer-link">
                                <a href="tel:{{ 'CONTACT-PHONE-NO-SPACE' | translate }}">
                                    <fa-icon class="flex-shrink-0 text-gray-400" [icon]="['fas', 'phone']"></fa-icon>
                                    <span class="ml-3">{{ "CONTACT-PHONE" | translate }}</span>
                                </a>
                            </dd>
                            <dt><span class="sr-only">Email</span></dt>
                            <dd class="flex text-lg footer-link">
                                <a href="mailto:{{ 'CONTACT-EMAIL' | translate }}">
                                <fa-icon class="flex-shrink-0 text-gray-400" [icon]="['fas', 'envelope']"></fa-icon>
                                    <span class="ml-3">{{ "CONTACT-EMAIL" | translate }}</span>
                                </a>
                            </dd>
                            <dt><span class="sr-only">Adresa</span></dt>
                            <dd class="flex text-lg footer-link">
                                <fa-icon class="flex-shrink-0 text-gray-400" style="width: 16px;" [icon]="['fas', 'map-marker-alt']"></fa-icon>
                                <div class="ml-3">
                                    Lawis Apartments<br>
                                    29. augusta 36/A<br>
                                    Poprad  058 01<br>
                                </div>
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-5 lg:col-start-7 lg:pl-16 pt-10 lg:pt-0;">
                <div class="divider-style mb-5">
                    <div class="short w-24 h-2 bg-white"></div>
                    <div class="long w-full h-px bg-white"></div>
                </div>
                <h2 class="text-gray-100 pb-5">
                    {{ "GOT-QUESTIONS" | translate }}
                </h2>
                <app-contact-form></app-contact-form>
            </div> 
            <div class="col-span-12 lg:col-span-12 pt-16">
                <div class="relative py-16 px-8 lg:px-16 w-full">
                    <div class="relative z-10 w-full text-center text-3xl text-gray-100 pt-16 pb-10">
                        {{ "READY-FOR-CHALLENGES" | translate }}
                    </div>.
                    <div class="absolute h-full w-full top-0 left-1 overflow-hidden z-0">
                        <img class="h-full w-full object-cover" src="/assets/images/024.jpg" alt='{{ "READY-FOR-CHALLENGES" | translate }}'>
                        <div class="absolute inset-0 bg-gray-500" style="mix-blend-mode: multiply;"></div>
                    </div>
                </div>
            </div>             
        </div>

        <div class="mt-8 border-t border-gray-700 pt-8 pb-3 grid grid-cols-4 md:grid-cols-12">
            <div class="mt-8 text-gray-400 md:mt-0 col-span-4">
                <b>{{ 'FOOTER-HEADLINE-COMPANY' | translate }}</b><br><br>
                <div class="text-sm" [innerHtml]="'COMPANY-LEGAL-INFO' | translate">
                </div>
            </div>
            <div class="mt-8 md:mt-0 col-span-4 text-gray-400">
                <b>{{ 'FOOTER-HEADLINE-SOCIAL' | translate }}</b><br><br>
                <a href="https://www.facebook.com/LAWIS-Apartments-101621225623417" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    Facebook
                </a><br>
                <a href="https://www.instagram.com/lawis_apartments" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    Instagram
                </a><br>
            </div>
            <div class="mt-8 md:mt-0 col-span-4 text-gray-400">
                <b>{{ 'FOOTER-HEADLINE-DOCS' | translate }}</b><br><br>
                <a href="{{ 'PRIVACY-LINK' | translate }}" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    {{ 'PRIVACY-TITLE' | translate }}
                </a><br>
                <a href="{{ 'TERMS-LINK' | translate }}" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    {{ 'TERMS-TITLE' | translate }}
                </a><br>
                <a href="{{ 'ACC-TERMS-LINK' | translate }}" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    {{ 'ACC-TERMS-TITLE' | translate }}
                </a><br>
                <a href="{{ 'GDPR-LINK' | translate }}" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    {{ 'GDPR-TITLE' | translate }}
                </a><br>
                <a href="{{ 'COMPETITION-LINK' | translate }}" target="_blank" class="text-sm text-gray-400 hover:text-gray-300">
                    {{ 'FOOTER-HEADLINE-COMPETITION' | translate }}
                </a><br>
            </div>
        </div>

        <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between pb-3">
            <div class="flex md:order-2 flex-col lg:flex-row lg:space-x-6">
                
            </div>
            <p class="mt-8 text-sm text-gray-400 md:mt-0 md:order-1">
                &copy; {{ currentYear }} Lawisapartments.sk | Všetky práva vyhradené
            </p>
        </div>
    </div>
</footer>
<div *ngIf="!cookiesAccepted" class="fixed inset-x-0 bottom-0 z-50">
    <div class="bg-gray-800">
        <div class="max-w-7xl mx-auto py-4 px-3 sm:px-6 lg:px-8 pb-6 sm:pb-4">
            <div class="flex items-center justify-between flex-wrap">
                <div class="w-full flex-1 flex items-center pb-6 sm:pb-0">
                    <span class="flex p-2 rounded-lg bg-green-500 w-10 text-center justify-center">
                        <fa-icon [icon]="['fas', 'info']"></fa-icon>
                    </span>
                    <p class="ml-4 font-sm text-white pr-5 md:pr-1">
                        <span class="inline">
                            {{ "COOKIE-TEXT" | translate }}
                        </span>
                    </p>
                </div>
                <div class="mt-2 flex-shrink-0 order-2 mt-0 w-full sm:w-auto pb-2">
                    <a class="btn btn-secondary py-2" (click)="acceptCookies()">
                        {{ "COOKIE-ACCEPT" | translate }}
                    </a>
                    <a class="btn btn-no-bg py-2" (click)="openModal(cookiesModal)">
                        {{ "COOKIE-REJECT" | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #cookiesModal let-modal>
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                    <input type="text" class="hidden">
                    <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <fa-icon class="text-xl text-green-500" [icon]="['fas', 'info']"></fa-icon>
                    </div>
                    <div class="mt-3 text-center sm:mt-5">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                           {{ "COOKIE-REJECTED-TITLE" | translate }}
                        </h3>
                        <div class="mt-2">
                            <p class="text-sm text-gray-500">
                                {{ "COOKIE-REJECTED-TEXT" | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="mt-5 sm:mt-6 items-center justify-content text-center">
                    <button type="button" class="mx-auto btn btn-primary" (click)="acceptCookies()">
                        {{ "COOKIE-REJECTED-ACCEPT" | translate }}
                    </button>
              </div>
            </div>
        </div>
    </div>
<script type="text/javascript">
    [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]
</script>
</ng-template>
    
<ngx-spinner></ngx-spinner>
