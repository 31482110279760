import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomePageComponent } from './pages/home-page/home-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { BlogIndexComponent } from './pages/blogs/blog-index/blog-index.component';
import { BlogItemComponent } from './pages/blogs/blog-item/blog-item.component';
import { PackageIndexComponent } from './pages/packages/package-index/package-index.component';
import { PackageItemComponent } from './pages/packages/package-item/package-item.component';

import { ErrorPageComponent } from './pages/error-page/error-page.component';


const routes: Routes = [
	{ path: '', redirectTo: '/sk', pathMatch: 'full' },
	{ path: 'en', component: HomePageComponent },
	{ path: 'en/404', component: ErrorPageComponent },
	//{ path: 'en/apartments', component: PackageIndexComponent },
	{ path: 'en/packages', component: PackageIndexComponent },
	{ path: 'en/packages/:slug', component: PackageItemComponent },
	{ path: 'en/blog', component: BlogIndexComponent },
	{ path: 'en/blog/:slug', component: BlogItemComponent },
	{ path: 'en/:*', redirectTo: 'en/404', pathMatch: 'full' },

	{ path: 'sk', component: HomePageComponent },
	{ path: 'sk/404', component: ErrorPageComponent },
	//{ path: 'sk/apartmany', component: PackageIndexComponent },
	{ path: 'sk/balicky', component: PackageIndexComponent },
	{ path: 'sk/balicky/:slug', component: PackageItemComponent },
	{ path: 'sk/blog', component: BlogIndexComponent },
	{ path: 'sk/blog/:slug', component: BlogItemComponent },
	{ path: 'sk/zasady-ochrany-osobnych-udajov', component: BlogItemComponent },
	{ path: 'sk/vseobecne-obchodne-podmienky', component: BlogItemComponent },
	{ path: 'sk/ubytovaci-poriadokv', component: BlogItemComponent },
	{ path: 'sk/:*', redirectTo: 'sk/404', pathMatch: 'full' },
];

@NgModule({
  	imports: [RouterModule.forRoot(routes, { 
  		preloadingStrategy: PreloadAllModules,
  		scrollPositionRestoration: 'enabled',
  		relativeLinkResolution: 'legacy' 
  	})],
  	exports: [RouterModule]
})
export class AppRoutingModule { }