import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  	providedIn: 'root'
})
export class PackageService {

  	constructor(
  		private http: HttpClient,
  		private env: EnvService,
  	) { }

  	getItemsList(lang:any): Observable<any> {
        return this.http.get(this.env.apiUrl + 'packages?locale='+lang);
    }

  	getItem(slug: string, lang: string){
  		return this.http.get(this.env.apiUrl + 'packages/' + slug + '?locale='+lang);
  	}
}

