<div id="products-page-wrapper">
	<div class="relative bg-green-500">
  		<div class="absolute inset-0">
    		<img class="w-full h-full object-cover" src="/assets/images/services/cover-4.jpeg" alt="">
    		<div class="absolute inset-0 bg-green-400" style="mix-blend-mode: overlay;" aria-hidden="true"></div>
  		</div>
	  	<div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
	    	<h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
	    		{{ "CONTACT-BANNER-HEADING" | translate }}
	    	</h1>
	    	<p class="mt-6 text-xl text-green-50 max-w-3xl">
	    		{{ "CONTACT-BANNER-SUBHEADING" | translate }}
	    	</p>
	  	</div>
	</div>
	<div class="relative bg-white pt-5 lg:pt-8 overflow-hidden sm:px-6 lg:px-8">
		<div class="container mx-auto px-4">
			<div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
				<div class="grid grid-cols-2">
					<div class="col-span-2 lg:col-span-1 lg:mb-10 lg:pr-8">
						<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d170453.342823457!2d17.04655817328138!3d48.12309194735072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c895d93b150c5%3A0x2fd320efbc06a139!2sBratislava%20Castle!5e0!3m2!1sen!2ssk!4v1617534509034!5m2!1sen!2ssk" height="350" style="border:0;" allowfullscreen="" loading="lazy" class="w-full"></iframe>
					</div>
					<div class="col-span-2 lg:col-span-1 mb-10 lg:pl-8 lg:mt-10">
						<dl class="my-8 space-y-6">
                			<dt><span class="sr-only">Telefón</span></dt>
                			<dd class="flex text-base text-gray-500">
                  				<fa-icon class="flex-shrink-0 text-gray-500" [icon]="['fas', 'phone']"></fa-icon>
	                  			<span class="ml-3 font-medium">{{ "CONTACT-PHONE" | translate }}</span>
                			</dd>
                			<dt><span class="sr-only">Email</span></dt>
                			<dd class="flex text-base text-gray-500">
                  				<fa-icon class="flex-shrink-0 text-gray-500" [icon]="['fas', 'envelope']"></fa-icon>
                  				<span class="ml-3 font-medium">{{ "CONTACT-EMAIL" | translate }}</span>
                			</dd>
                			<dt><span class="sr-only">Adresa</span></dt>
                			<dd class="flex text-base text-gray-500">
                  				<fa-icon class="flex-shrink-0 text-gray-500" style="width: 16px;" [icon]="['fas', 'map-marker-alt']"></fa-icon>
                  				<!--
                  				<div class="ml-3">
                  					Elite Servis s.r.o.<br>
									Ulica 123<br>
									Mesto 999 11<br>
                  				</div>
                  			-->
                			</dd>
              			</dl>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-gray-100">
		<div class="container mx-auto px-4 py-5">
		  	<div class="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 py-10">
		    	<div class="text-center">
		      		<h2 class="text-base text-green-500 font-semibold tracking-wide uppercase">
		      			Kontaktujte nás jednoducho cez
		      		</h2>
		      		<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
		        		Kontaktný formulár
		      		</p>
		    	</div>

		    	<div class="mt-10 mb-5 ">
		      		<app-contact-form></app-contact-form>
		    	</div>
		  	</div>
		</div>
	</div>
</div>


