
<div *ngIf="blog">
	<div class="relative bg-gray-900 max-w-screen-2xl mx-auto">
		<div class="grid grid-cols-12 pt-5 relative z-10">
			<div class="col-span-12 px-8 md:px-16 pt-56 pb-56 md:pt-48 md:pb-48 fade-in-content">
				
			</div>
			<div class="col-span-12 px-8 md:px-16 pt-8 fade-in-content">
				<div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
					<a *ngFor="let item of categories; let i = index" class="blog-category-link flex py-5 items-center content-center justify-center bg-black bg-opacity-50 text-white border-black cursor-pointer" [class.border-r-0]="i == 3" [class.border-r]="i != 3" (click)="openCategory(item.id)" [class.bg-pink-700]="blog.blog_category.id == item.id">
						<div class="px-3 w-16">
							<div class="relative overflow-hidden">
								<img class="w-full relative" src="{{ item.image }}" alt="{{ item.title }}">
							</div>
						</div>
						<div class="w-1/2 text-center">
							<div class="relative overflow-hidden">
								<h3>
									{{ item.title }}
								</h3>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="absolute w-full h-full top-0 z-0 overflow-hidden">
			<img class="h-full w-full object-cover section-cover" src="{{ blog.image }}" alt="{{ blog.title }}">
			<!--<div class="absolute inset-0 bg-gray-500" style="mix-blend-mode: multiply;"></div>-->
		</div>
	</div>
	<div class="relative max-w-screen-md mx-auto py-10 article-detail">
		<div class="px-8 md:px-16">
			<div class="grid grid-cols-12 flex" photoGalleryGroup>
				<div class="mt-10 text-justify col-span-12">
					<div class="divider-style mb-5">
						<div class="short w-24 h-2 bg-white"></div>
						<div class="long w-full h-px bg-white"></div>
					</div>
					<h2 class="text-3xl font-extrabold tracking-tight text-white pb-16" [innerHtml]='blog.title'>
						
					</h2>
				</div>
				<div class="text-justify col-span-12" [innerHtml]=" blog.intro_text">
					
				</div>
				<div class="mt-10 text-justify col-span-12" *ngFor="let item of mainContentObj">
					<div [innerHtml]="item.content" *ngIf="item.type === 'text'">

					</div>
					<img src="{{item.content}}" *ngIf="item.type === 'image'" alt="{{blog.title}}" [photoGallery]="item.content"/>

				</div>

			<!--
				<div class="mt-10 text-justify col-span-12" [innerHtml]=" blog.intro_text">
					
				</div>
				<div class="mt-10 text-justify col-span-12" [innerHtml]=" blog.full_text">
					
				</div>
			-->
			<!--
				<div class="col-span-12" *ngIf="blog.gallery_items">
					<div class="mt-10 mb-10 text-justify col-span-12" *ngIf="blog.gallery_items.length > 0" photoGalleryGroup>
						<div class="-mx-2">
							<div *ngFor="let galleryItem of blog.gallery_items; let i = index" class="w-1/4 inline-block px-2 py-1">
								<img class="w-full shadow-lg cursor-pointer" [photoGallery]="galleryItem" src="{{ galleryItem }}" alt="{{ blog.title + ' ' + i}}">
							</div>
						</div>
					</div>
				</div>
				-->
			</div>
		</div>
	</div>
	<div *ngIf="relatedBlogs" class="relative max-w-screen-md mx-auto">
		<div class="px-8 md:px-16">
			<div *ngIf="relatedBlogs.length" class="grid grid-cols-12 gap-5 md:gap-6 py-10 lg:gap-10">
				<div class="col-span-12">
					<div class="divider-style">
						<div class="short w-24 h-2 bg-white"></div>
						<div class="long w-full h-px bg-white"></div>
					</div>
			      	<div class="mt-8">
			        	<h2 class="text-3xl font-extrabold tracking-tight text-white">
							{{ "RELATED-BLOGS" | translate }}
			        	</h2>
			      	</div>
				</div>
				<div *ngFor="let blog of relatedBlogs; let i = index" class="col-span-12 md:col-span-6 lg:col-span-6 mb-12">
					<div class="relative">
						<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
							{{ blog.blog_category.title }}
						</div>
						<a class="overflow-hidden block h-60" [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
							<img class="w-full h-full object-cover object-cover-link z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
						</a>
					</div>

			      	<div class="mt-6">
			        	<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
			        		<h2 class="py-3 text-xl text-white">
								{{ blog.title }}
			        		</h2>
			        	</a>
			        	<div class="text-justify" [innerHtml]=" blog.intro_text">
							
						</div>
			      	</div>
			    </div>
			    <!--
			    <div class="col-span-12">
			    	<div class="pr-16 pt-5 w-full text-center">
						<a class="btn" href="{{ 'MENU-LINK-3' | translate }}">
							{{ 'EXPLORE-CTA' | translate }}
						</a>
					</div>
			    </div>
			-->
			</div>
		</div>
	</div>
	<div class="relative max-w-screen-md mx-auto">
		<div class="px-8 md:px-16">
			<div class="grid grid-cols-12 gap-5 md:gap-6 py-5 lg:gap-10">
			    <div class="col-span-12">
			    	<div class="pt-5 w-full text-center">
						<a class="btn btn-outline" href="{{ 'MENU-LINK-3' | translate }}">
							{{ 'BACK' | translate }}
						</a>
					</div>
			    </div>
			</div>
		</div>
	</div>
</div>