import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

//import { AuthService } from 'src/app/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

	constructor(
		//private authService: AuthService,
        private router: Router,
	){

	}

	intercept(req: HttpRequest<any>, next: HttpHandler):  Observable<HttpEvent<any>> {
      	//const token = this.authService.isAuthenticated();
      	let newHeaders = req.headers;
        /*
      	if (token) {
         	newHeaders = newHeaders.append('Authorization', 'Bearer '+token);
      	}
        */
      	const authReq = req.clone({headers: newHeaders});
      	
      	return next.handle(authReq).pipe( tap(() => {},
            (err: any) => {
               if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    this.router.navigate(['login']);
                }
            })
        );
   }
}
