
<div class="mt-10 sm:mt-0">
  	<div class="md:grid md:grid-cols-1 md:gap-6">
    	<div class="mt-5 md:mt-0 md:col-span-1">
      		<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<div class="overflow-hidden sm:rounded-md">
					<div class="py-5">
						<div class="grid grid-cols-6 gap-6">
							<div class="col-span-6 sm:col-span-3 px-1">
								<label for="object_type" class="block text-sm font-medium text-gray-700">{{ "QUOTE-OBJECT-TYPE" | translate }}</label>
								<select id="object_type" name="object_type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" formControlName="object_type" required>
									<option [ngValue]="null" disabled>Vyberte možnosť</option>
									<option *ngFor="let object of objectTypes" [ngValue]="object.id">{{ object.title }}</option>
								</select>
							</div>

							<div class="col-span-6 sm:col-span-3 px-1">
								<label for="area" class="block text-sm font-medium text-gray-700">{{ "QUOTE-AREA" | translate }}</label>
								<input type="text" name="area" id="area" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"formControlName="area" required>
							</div>
							
							<div class="col-span-6 px-1">
								<div class="grid grid-cols-6">
									<div class="col-span-6">
										<label for="location" class="text-sm font-medium text-gray-700">{{ "QUOTE-LOCATION" | translate }}</label>
									</div>
									<div class="col-span-6 sm:col-span-3 sm:mr-4">
										
										<input type="text" name="location" id="location" autocomplete="location" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" formControlName="location" required>
									</div>

									<div class="col-span-6 sm:col-span-3 sm:ml-4 mt-8 sm:mt-0">
										<div class="relative grid grid-cols-2">
											<div class="select-option rounded-tl-md bg-white rounded-bl-md relative border cursor-pointer border-gray-300 z-10" [ngClass]="(form.value.frequency==='one-time')?'active':'passive'">
												<label class="relative block px-3 py-3 cursor-pointer text-center">
											      	<input type="radio" name="frequency" value="one-time" class="sr-only" aria-labelledby="server-size-0-label" aria-describedby="server-size-0-description-0 server-size-0-description-1"formControlName="frequency" required>
												      	<div class="flex items-center">
												        	<div class="text-sm mx-auto">
											            		{{ "QUOTE-ONE-TIME" | translate }}
												        	</div>
												      	</div>
												      	<!-- Checked: "border-indigo-500", Not Checked: "border-transparent" -->
												      	<div class="border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
											    </label>
											</div>

											<div class="select-option rounded-br-md rounded-tr-md -ml-px relative border cursor-pointer border-gray-300 bg-white z-10" [ngClass]="(form.value.frequency==='regular')?'active':'passive'">
											    <label class="relative block px-3 py-3 cursor-pointer text-center">	
												      <input type="radio" name="frequency" value="regular" class="sr-only" aria-labelledby="server-size-1-label" aria-describedby="server-size-1-description-0 server-size-1-description-1" formControlName="frequency" required>
												      	<div class="flex items-center text-center">
												        	<div class="text-sm mx-auto">
											            		{{ "QUOTE-REGULAR" | translate }}
												        	</div>
												      	</div>
												      	
												      	<!-- Checked: "border-indigo-500", Not Checked: "border-transparent" -->
												      	<div class="border-transparent absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true"></div>
											    </label>
											</div>
										</div>
									</div>
								</div>
							</div>
							

							<div class="py-5 col-span-6">
						    	<div class="border-t border-gray-200"></div>
						  	</div>

						  	<div class="col-span-6 sm:col-span-3 px-1">
								<label for="name" class="block text-sm font-medium text-gray-700">{{ "QUOTE-NAME" | translate }}</label>
								<input type="text" name="name" id="name" autocomplete="name" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" formControlName="name" required>
							</div>

							<div class="col-span-6 sm:col-span-3 px-1">
								<label for="email" class="block text-sm font-medium text-gray-700">{{ "QUOTE-CONTACT" | translate }}</label>
								<input type="text" name="email" id="email" autocomplete="email" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" formControlName="email" required>
							</div>

							<div class="col-span-6 sm:col-span-6 lg:col-span-6 px-1">
								<label for="about" class="block text-sm font-medium text-gray-700">
									{{ "QUOTE-MESSAGE" | translate }}
								</label>
								<div class="mt-1">
									<textarea id="message" name="message" rows="5" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md" placeholder="{{ 'CONTACT-FORM-MESSAGE-PLACEHOLDER' | translate }}" formControlName="message" required></textarea>
								</div>
							</div>
							<div class="col-span-6 sm:col-span-6 lg:col-span-6 px-1">
								<div class="flex items-center">
				                  	<input id="terms" name="terms" type="checkbox" class="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-0 ring-offset-0 focus:border-gray-300" formControlName="terms" required>
				                  	<label for="terms" class="ml-2 block text-sm text-gray-900">
				                    	{{ "ACCEPT-TERMS" | translate }} <a class="text-blue-500" target="_blank" href="{{ 'TERMS-LINK' | translate }}">{{ "ACCEPT-TERMS-MORE-INFO" | translate }}</a>
				                  	</label>
				                </div>
				            </div>
				            <div class="col-span-6 sm:col-span-3 px-1 hidden">
								<label for="name" class="block text-sm font-medium text-gray-700">Adresa</label>
								<input type="text" name="address_new_city" id="address_new_city" autocomplete="address_new_city" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" formControlName="address_new_city">
							</div>
						</div>
						<div class="d-grid gap-2 d-md-flex justify-content-md-start text-right mt-10 px-1">
			    			<button class="btn btn-primary" [disabled]="!form.valid">
								{{ "CONTACT-FORM-SUBMIT" | translate }}
							</button>
			  			</div>
					</div>
				</div>
			</form>
    	</div>
  	</div>
</div>
