<form [formGroup]="form" (ngSubmit)="onSubmit()">
	<div class="overflow-hidden sm:rounded-md">
		<div class="py-5">
			<div class="grid grid-cols-6 gap-6">
				<div class="col-span-6 sm:col-span-3 px-1">
					<label for="name" class="block text-sm font-medium text-gray-400">{{ "CONTACT-FORM-NAME" | translate }}</label>
					<input type="text" name="name" id="name" autocomplete="name" class="mt-1 block w-full sm:text-sm border-black bg-opacity-50 bg-black text-white" formControlName="name" required>
				</div>

				<div class="col-span-6 sm:col-span-3 px-1">
					<label for="email" class="block text-sm font-medium text-gray-400">{{ "CONTACT-FORM-EMAIL" | translate }}</label>
					<input type="text" name="email" id="email" autocomplete="email" class="mt-1 block w-full sm:text-sm border-black bg-opacity-50 bg-black text-white" formControlName="email" required email>
				</div>

				<div class="col-span-6 sm:col-span-6 lg:col-span-6 px-1">
					<label for="about" class="block text-sm font-medium text-gray-400">
						{{ "CONTACT-FORM-MESSAGE" | translate }}
					</label>
					<div class="mt-1">
						<textarea id="message" name="message" rows="5" class="mt-1 block w-full sm:text-sm border-black bg-opacity-50 bg-black text-white" placeholder="{{ 'CONTACT-FORM-MESSAGE-PLACEHOLDER' | translate }}" formControlName="message" required></textarea>
					</div>
				</div>
				<div class="col-span-6 sm:col-span-6 lg:col-span-6 px-1">
					<div class="flex items-center">
	                  	<input id="terms" name="terms" type="checkbox" class="h-4 w-4 mt-1 border-black bg-opacity-50 bg-black text-pink-700" formControlName="terms" required>
	                  	<label for="terms" class="ml-4 block text-sm text-gray-400">
	                    	{{ "ACCEPT-TERMS" | translate }} <a class="text-white" target="_blank" href="{{ 'TERMS-LINK' | translate }}">{{ "ACCEPT-TERMS-MORE-INFO" | translate }}</a>
	                  	</label>
	                </div>
	            </div>
	            <div class="col-span-6 sm:col-span-3 px-1 hidden">
					<label for="name" class="block text-sm font-medium text-gray-400">Adresa</label>
					<input type="text" name="address_new_city" id="address_new_city" autocomplete="address_new_city" class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" formControlName="address_new_city">
				</div>
			</div>
			<div class="d-grid gap-2 d-md-flex justify-content-md-start text-left mt-10">
    			<button type="submit" [class.disabled]="!form.valid" class="btn btn-outline w-2/3">
					{{ "CONTACT-FORM-SUBMIT" | translate }}
				</button>
  			</div>
		</div>
	</div>
</form>