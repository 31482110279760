import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { AlertService } from 'src/app/services/alert.service';

@Component({
  	selector: 'app-main-nav',
  	templateUrl: './main-nav.component.html',
  	styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {

  	constructor(
  		private router: Router,
  		private alertService: AlertService
  	) { }

  	ngOnInit(): void {
  	}

  	scrollToContact(){
  		document.getElementById("contactBlock").scrollIntoView({
	      	behavior: "smooth",
	      	block: "start",
	      	inline: "nearest"
	    });
	    this.alertService.toggleMenuAction.emit();
  		//this.router.navigate(['/'], { fragment: "contactBlock" });
  	}

  	closeMenu(){
  		this.alertService.toggleMenuAction.emit(false);
  	}

}
