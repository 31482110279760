<section class="py-2">
  	<div class="relative">
<!--
    	<div *ngFor="let testimonial of testimonials" class="relative">
    	-->
    	<div class="swiper-button-next w-2 h-5 text-gray-700 z-30 text-xs hidden lg:block">
    	</div>
    	<!--
    	<div class="swiper-button-prev w-3 h-6 text-gray-700">
    	</div>
    -->

    <!--
    	<div class="absolute w-12 h-full bg-gradient-to-r from-transparent to-gray-900 top-0 right-0 z-20 hidden lg:block">
    	</div>
    -->
    	<swiper *ngIf="testimonialsActivate" [config]="config" #testimonialsSwiper class="z-10 mb-2">
    		<div class="swiper-wrapper">
      			<div *ngFor="let testimonial of testimonials" class="swiper-slide">
			    	<div class="relative h-full">
			      		<blockquote class="swiper-inner sm:pr-6 md:pr-14 h-full">
			      			<div class="text-white mb-6">
								<app-custom-icons iconSlug="star" classes="h-5 w-5 inline-block mr-1 text-white"></app-custom-icons>
								<app-custom-icons iconSlug="star" classes="h-5 w-5 inline-block mr-1 text-white"></app-custom-icons>
								<app-custom-icons iconSlug="star" classes="h-5 w-5 inline-block mr-1 text-white"></app-custom-icons>
								<app-custom-icons iconSlug="star" classes="h-5 w-5 inline-block mr-1 text-white"></app-custom-icons>
								<app-custom-icons iconSlug="star" classes="h-5 w-5 inline-block mr-1 text-white"></app-custom-icons>
							</div>
			        		<div class="max-w-md text-gray-400 flex-grow">
			          			<p class="text-justify">
			            			{{ testimonial.text }}
			          			</p>
			        		</div>
			        		<footer class="mt-6">
			          			<div class="md:flex">
			            			<div class="md:flex-shrink-0">
			            				<!--
			              				<img class="mx-auto h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=9jtzu43KCc&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">
			              			-->
			            			</div>
			            			<div class="mt-3 md:mt-0 md:flex" *ngIf="testimonial.name">
			              				<div class="text-base font-medium text-gray-400 font-bold">
			              					{{ testimonial.name }}
			              				</div>

			              				<div class="text-white">
				              				<svg *ngIf="testimonial.desc" class="hidden md:block mx-1 h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
				                				<path d="M11 0h3L9 20H6l5-20z" />
				              				</svg>
				              			</div>

			              				<div class="text-base font-bold text-gray-400">
			              					{{ testimonial.desc }}
			              				</div>
			            			</div>
			          			</div>
			        		</footer>
			      		</blockquote>
			    	</div>
			    </div>
			</div>
			
		</swiper>
		<div class="swiper-pagination mt-5 w-full"></div>
  	</div>
</section>