<div id="error-page-wrapper">
	<div class="relative bg-green-500">
	  	<div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8 h-full bg-green-500">
	    	<h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
	    		{{ "ERROR-NOT-FOUND-HEADING" | translate }}
	    	</h1>
	    	<p class="mt-6 text-xl text-green-100 max-w-3xl">
	    		{{ "ERROR-NOT-FOUND-TEXT" | translate }}
	    	</p>
	  	</div>
	</div>
</div>
