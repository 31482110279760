<div class="relative bg-gray-900 max-w-screen-2xl mx-auto mb-5 sm:mb-12">
	<div class="grid grid-cols-12 py-5 md:py-16 lg:py-40 relative z-10 text-center">
		<div class="col-span-12 md:col-span-8 md:col-start-3 pl-8 pr-8 md:pl-16 md:pr-16 pt-48 pb-40 fade-in-content">
			<h1 class="text-gray-100 pb-5 text-center leading-tight" [innerHtml]='"HOME-HEADLINE" | translate'>
			</h1>
			
			<p class="pt-2 pb-5 text-gray-300 text-center text-xl" [innerHtml]='"HOME-SUBHEADLINE" | translate'>
			</p>
		</div>
	</div>
	<div class="absolute w-full h-full top-0 z-0 overflow-hidden">
		<img class="h-full w-full object-cover section-cover" src="/assets/images/titulna.jpg" alt="{{ 'HOME-HEADLINE' | translate }}">
		<div class="absolute inset-0 bg-gray-500" style="mix-blend-mode: multiply;"></div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-2xl mx-auto mb-12">
	
	<div class="grid grid-cols-12 py-5 relative z-10 px-8 md:px-16">
		<div class="col-span-12 sm:col-span-6 lg:col-span-8 pb-10 sm:pb-2">
			<app-testimonials>

			</app-testimonials>
		</div>
		<div class="col-span-12 sm:col-span-6 lg:col-span-4 sm:pl-5 md:pl-8">
			<div class="bg-black bg-opacity-50 px-12 lg:px-8 xl:px-14 pt-10 pb-16">
				<div class="text-gray-100 mb-5">
					<app-custom-icons iconSlug="benefits" classes="h-10 w-10 inline-block mr-1"></app-custom-icons>
				</div>
				<h2 class="pb-1 items-center text-gray-100">
					
					<div class="">
						{{ "BENEFITS-HEADLINE" | translate }}
					</div>
				</h2>
				<p>
					{{ "BENEFITS-SUBHEADLINE" | translate }}
				</p>
				<br>
				<div class="mt-3">
					<a class="btn" href="{{ 'RESERVATION-LINK' | translate }}">
						{{ "SIGN-UP" | translate }}
					</a>
				</div>
			</div>
		</div>
	</div>

</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto pb-8">
	<div class="grid grid-cols-12 py-5">
		<div class="col-span-12 lg:col-span-7 px-8 lg:pr-16 lg:pl-0">
			<div class="lg:hidden pt-10">
				<div class="divider-style">
					<div class="short w-24 h-2 bg-white"></div>
					<div class="long w-full h-px bg-white"></div>
				</div>
		      	<div class="mt-8">
		        	<h2 class="text-3xl font-extrabold tracking-tight text-white">
						{{ apartments[0].title }}
		        	</h2>
		        	<div class="pt-8 pb-20 text-justify">
						{{ apartments[0].text }}
					</div>
		      	</div>
		    </div>
			<div class="relative overflow-hidden" photoGalleryGroup>
				<div class="relative overflow-hidden">
					<img class="w-full relative section-cover cursor-pointer" [photoGallery]="apartments[0].mainImage" src="{{ apartments[0].mainImage }}" alt="{{ apartments[0].title }}">
				</div>
				<div class="relative lg:absolute lg:bottom-4 lg:right-6 z-20 mt-8 lg:mt-0 clear-both w-full lg:w-80">
					<div *ngIf="apartments[1].images.length > 0" class="w-1/2 inline-block pr-4">
						<img class="w-full shadow-lg cursor-pointer" [photoGallery]="apartments[0].images[0]" src="{{ apartments[0].images[0] }}" alt="{{ apartments[0].title }}">
					</div>
					<div *ngIf="apartments[0].images.length > 1" class="w-1/2 inline-block pl-4">
						<div class="relative">
							<div class="absolute inset-0 pointer-events-none z-20 align-middle flex flex-wrap content-center justify-center text-center">
								<div class="text-white text-xl flex items-center align-middle ">
									+ {{ apartments[0].images.length - 1 }}
								</div>
							</div>
							<div class="absolute inset-0 bg-black bg-opacity-50 pointer-events-none z-10"></div>
							<img class="w-full shadow-lg cursor-pointer" [photoGallery]="apartments[0].images[1]" src="{{ apartments[0].images[1] }}" alt="{{ apartments[0].title }}">
						</div>
					</div>
					<div *ngFor="let image of apartments[0].images; let i = index" class="hidden">
						<img *ngIf="i > 1" class="w-full cursor-pointer" [photoGallery]="image" src="{{ image }}" alt="{{ apartments[0].title }}">
					</div>
				</div>
				<div class="hidden lg:block absolute bottom-0 -mb-8 left-0 h-20 w-full bg-gradient-to-t from-black bg-opacity-50 z-10 pointer-events-none">
				</div>
			</div>
			<div class="mt-10 mb-5 grid grid-cols-2 xl:grid-cols-3 lg:pl-16">
    			<div *ngFor="let feature of apartments[0].features" class="flex items-center feature-list-item">
        			<div class="flex items-center justify-center h-14 w-14 text-white">
        				<app-custom-icons iconSlug="{{ feature.icon }}" classes="h-8 w-8"></app-custom-icons>
    				</div>
    				<div class="inline-block text-gray-500 pl-5">
    					{{ feature.text }}
    				</div>
    			</div>
    		</div>
		</div>
		<div class="col-span-5 pr-16 hidden lg:block">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-8">
	        	<h2 class="text-3xl font-extrabold tracking-tight text-white">
					{{ apartments[0].title }}
	        	</h2>
	        	<div class="pt-8 pb-20 text-justify">
					{{ apartments[0].text }}
				</div>
	    		<img class="w-full" src="{{ apartments[0].secondaryImage }}" alt="{{ apartments[0].title }}">
	      	</div>
	    </div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto py-8">
	<div class="grid grid-cols-12 py-5">
		<div class="col-span-12 lg:col-span-5 px-8 lg:pl-16 lg:pr-0">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-8 relative">
	      		<h2 class="text-3xl font-extrabold tracking-tight text-white">
					{{ apartments[1].title }}
	        	</h2>
	        	<div class="pt-8 pb-20 text-justify">
					{{ apartments[1].text }}
				</div>
	    		<img class="w-full hidden lg:block" src="{{ apartments[1].secondaryImage }}" alt="{{ apartments[1].title }}">
	      	</div>
	    </div>
		<div class="col-span-12 lg:col-span-7 px-8 lg:pl-16 lg:pr-0">
			<div class="relative overflow-hidden" photoGalleryGroup>
				<div class="relative overflow-hidden">
					<img class="w-full relative section-cover cursor-pointer" src="{{ apartments[1].mainImage }}" [photoGallery]="apartments[1].mainImage" alt="{{ apartments[1].title }}">
				</div>
				<div class="relative lg:absolute lg:bottom-4 lg:left-6 z-20 mt-8 lg:mt-0 clear-both w-full lg:w-80">
					<div *ngIf="apartments[1].images.length > 0" class="w-1/2 inline-block pr-4">
						<img class="w-full shadow-lg cursor-pointer" [photoGallery]="apartments[1].images[0]" src="{{ apartments[1].images[0] }}" alt="{{ apartments[1].title }}">
					</div>
					<div *ngIf="apartments[1].images.length > 1" class="w-1/2 inline-block pl-4">
						<div class="relative">
							<div class="absolute inset-0 pointer-events-none z-20 align-middle flex flex-wrap content-center justify-center text-center">
								<div class="text-white text-xl flex items-center align-middle ">
									+ {{ apartments[1].images.length - 1 }}
								</div>
							</div>
							<div class="absolute inset-0 bg-black bg-opacity-50 pointer-events-none z-10"></div>
							<img class="w-full shadow-lg cursor-pointer" [photoGallery]="apartments[1].images[1]" src="{{ apartments[1].images[1] }}" alt="{{ apartments[1].title }}">
						</div>
					</div>
					<div *ngFor="let image of apartments[1].images; let i = index" class="hidden">
						<img *ngIf="i > 1" class="w-full cursor-pointer" [photoGallery]="image" src="{{ image }}" alt="{{ apartments[1].title }}">
					</div>
				</div>
				<div class="absolute bottom-0 -mb-8 left-0 h-20 w-full bg-gradient-to-t from-black bg-opacity-50 z-10 pointer-events-none  hidden lg:block">
				</div>
			</div>
			<div class="mt-10 mb-5 grid grid-cols-2 xl:grid-cols-3 lg:pr-16">
				<div *ngFor="let feature of apartments[1].features" class="flex items-center feature-list-item">
        			<div class="flex items-center justify-center h-14 w-14 text-white">
        				<app-custom-icons iconSlug="{{ feature.icon }}" classes="h-8 w-8"></app-custom-icons>
    				</div>
    				<div class="inline-block text-gray-500 pl-5">
    					{{ feature.text }}
    				</div>
    			</div>
    		</div>
		</div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto lg:pb-8 pb-12 py-8">
	<div class="grid grid-cols-12 py-5">
		<div class="col-span-12 lg:col-span-5 px-8 lg:pl-16 lg:pr-0">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-8 relative">
	      		<h2 class="text-3xl font-extrabold tracking-tight text-white">
					{{ specialoffer[0].title }}
	        	</h2>
	        	<div class="pt-8 text-justify font-bold text-gray-400">
					{{ specialoffer[0].intro }}
				</div>
				<div class="pt-8 pb-20 text-justify" [innerHtml]="specialoffer[0].text">
				</div>
				<!--
	    		<img class="w-full hidden lg:block" src="{{ specialoffer[0].secondaryImage }}" alt="{{ specialoffer[0].title }}">
	    		-->
	      	</div>
	    </div>
		<div class="col-span-12 lg:col-span-7 px-8 lg:pl-16 lg:pr-0">
			<div class="relative overflow-hidden" photoGalleryGroup>
				<div class="relative overflow-hidden">
					<img class="w-full relative section-cover cursor-pointer" src="{{ specialoffer[0].mainImage }}" [photoGallery]="specialoffer[0].mainImage" alt="{{ specialoffer[0].title }}">
				</div>
				<div class="relative lg:absolute lg:bottom-4 lg:left-6 z-20 mt-8 lg:mt-0 clear-both w-full lg:w-80">
					<div *ngIf="specialoffer[0].images.length > 0" class="w-1/2 inline-block pr-4">
						<img class="w-full shadow-lg cursor-pointer" [photoGallery]="specialoffer[0].images[0]" src="{{ specialoffer[0].images[0] }}" alt="{{ specialoffer[0].title }}">
					</div>
					<div *ngIf="specialoffer[0].images.length > 1" class="w-1/2 inline-block pl-4">
						<div class="relative">
							<div class="absolute inset-0 pointer-events-none z-20 align-middle flex flex-wrap content-center justify-center text-center">
								<div class="text-white text-xl flex items-center align-middle ">
									+ {{ specialoffer[0].images.length - 1 }}
								</div>
							</div>
							<div class="absolute inset-0 bg-black bg-opacity-50 pointer-events-none z-10"></div>
							<img class="w-full shadow-lg cursor-pointer" [photoGallery]="specialoffer[0].images[1]" src="{{ specialoffer[0].images[1] }}" alt="{{ specialoffer[0].title }}">
						</div>
					</div>
					<div *ngFor="let image of specialoffer[0].images; let i = index" class="hidden">
						<img *ngIf="i > 1" class="w-full cursor-pointer" [photoGallery]="image" src="{{ image }}" alt="{{ specialoffer[0].title }}">
					</div>
				</div>
				<div class="absolute bottom-0 -mb-8 left-0 h-20 w-full bg-gradient-to-t from-black bg-opacity-50 z-10 pointer-events-none  hidden lg:block">
				</div>
			</div>
		</div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto pt-6 pb-10">
	<div class="grid grid-cols-12 py-5 flex">
		<div class="col-span-12 lg:col-span-6 pr-8 pl-8 lg:pl-0">
			<div class="relative">
				<img class="w-full relative object-cover" src="/assets/images/020_2.jpg" alt="{{ 'OFFER-TITLE' | translate }}">
			</div>
		</div>
		<div class="col-span-12 lg:col-span-6 pr-8 lg:pr-16 pl-8 mt-10 lg:mt-0">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-6">
	        	<h3 class="text-xl font-extrabold uppercase text-white pt-2 pb-8">
					{{ 'OFFER-TITLE' | translate }}
	        	</h3>
	        	<h2 class="text-3xl font-extrabold tracking-tight text-white pb-7">
	        		{{ 'OFFER-SUBTITLE' | translate }}
	        	</h2>
	        	<div class="lg:pr-16 text-justify" [innerHtml]="'OFFER-TEXT' | translate">
					
				</div>
				<div class="lg:pr-16 pt-10">
					<a class="btn btn-outline" [routerLink]="'MENU-LINK-2' | translate">
						{{ 'OFFER-EXPLORE' | translate }}
					</a>
				</div>
	      	</div>
	    </div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto py-10 lg:py-8">
	<div class="grid grid-cols-12 py-5 flex">
		<div class="col-span-12 lg:col-span-6 pl-8 lg:pl-16 pr-8 pb-16 order-2 lg:order-1 mt-10 lg:mt-0">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-6">
	        	<h3 class="text-xl font-extrabold uppercase text-white pt-2 pb-8">
					{{ 'PRICING-TITLE' | translate }}
	        	</h3>

	        	<h2 class="text-2xl font-extrabold tracking-tight text-white pb-2">
	        		{{ apartments[0].title }}
	        	</h2>

	        	<div class="pt-2">
	        		<div class="flex flex-col">
  						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    						<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      							<div class="overflow-hidden">
									<table class="divide-y divide-gray-900 min-w-full pricing-table">
						          		<thead class="">
						            		<tr>
									            <th scope="col" class="table-head-item">
									                {{ 'NUM-OF-GUESTS' | translate }}
									            </th>
									            <!--
									            <th scope="col" class="table-head-item">
									               	{{ 'ONE-NIGHT' | translate }}
									            </th>
									        	-->
									            <th scope="col" class="table-head-item">
									               	{{ 'TWO-AND-MORE-NIGHTS' | translate }}
									            </th>
									        </tr>
						          		</thead>
						          		<tbody>
								            <tr class="bg-black bg-opacity-50 text-center">
								            	<td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-2-ROW-1-COL-1' | translate }}
									            </td>
									            <!--
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-1-COL-2' | translate }}
									            </td>
									        	-->
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-1-COL-3' | translate }}
									            </td>
								            </tr>
								            <tr class="bg-black bg-opacity-30 text-center">
								                <td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-2-ROW-2-COL-1' | translate }}
									            </td>
									            <!--
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-2-COL-2' | translate }}
									            </td>
									        	-->
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-2-COL-3' | translate }}
									            </td>
								            </tr>
								            <tr class="bg-black bg-opacity-50 text-center">
								            	<td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-2-ROW-3-COL-1' | translate }}
									            </td>
									            <!--
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-3-COL-2' | translate }}
									            </td>
									        -->
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-3-COL-3' | translate }}
									            </td>
								            </tr>
								            <!--
								            <tr class="bg-black bg-opacity-30 text-center">
								                <td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-2-ROW-4-COL-1' | translate }}
									            </td>
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-4-COL-2' | translate }}
									            </td>
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-2-ROW-4-COL-3' | translate }}
									            </td>
								            </tr>
								        -->
							          	</tbody>
							        </table>
							        <div class="pricing-notice">
							        	{{ 'PRICING-NOTICE' | translate }}
							        </div>
			    				</div>
							</div>
						</div>
					</div>
				</div>

				<h2 class="text-2xl font-extrabold tracking-tight text-white pb-2 pt-8">
	        		{{ apartments[1].title }}
	        	</h2>

	        	<div class="pt-2">
	        		<div class="flex flex-col">
  						<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    						<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      							<div class="overflow-hidden">
									<table class="divide-y divide-gray-900 min-w-full pricing-table">
						          		<thead class="">
						            		<tr>
									            <th scope="col" class="table-head-item">
									                {{ 'NUM-OF-GUESTS' | translate }}
									            </th>
									            <!--
									            <th scope="col" class="table-head-item">
									               	{{ 'ONE-NIGHT' | translate }}
									            </th>
									            -->
									            <th scope="col" class="table-head-item">
									               	{{ 'TWO-AND-MORE-NIGHTS' | translate }}
									            </th>
									        </tr>
						          		</thead>
						          		<tbody>
								            <tr class="bg-black bg-opacity-50 text-center">
								            	<td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-1-ROW-1-COL-1' | translate }}
									            </td>
									            <!--
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-1-ROW-1-COL-2' | translate }}
									            </td>
									        	-->
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-1-ROW-1-COL-3' | translate }}
									            </td>
								            </tr>
								            <tr class="bg-black bg-opacity-30 text-center">
								                <td class="table-body-item table-body-item-highlighted">
								                	{{ 'PRICING-TAB-1-ROW-2-COL-1' | translate }}
									            </td>
									            <!--
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-1-ROW-2-COL-2' | translate }}
									            </td>
									        	-->
									            <td class="table-body-item">
									                {{ 'PRICING-TAB-1-ROW-2-COL-3' | translate }}
									            </td>
								            </tr>
							          	</tbody>
							        </table>
							        <div class="pricing-notice">
							        	{{ 'PRICING-NOTICE' | translate }}
							        </div>
			    				</div>
							</div>
						</div>
					</div>
				</div>

				<div class="pr-16 pt-10 mt-6">
					<a class="btn" target="_blank" href='{{ "RESERVATION-LINK" | translate }}'>
						{{ 'PRICING-CTA' | translate }}
					</a>
				</div>

	      	</div>
	    </div>
		<div class="col-span-12 lg:col-span-6 pr-8 lg:pr-0 pl-8 order-1 lg:order-2">
			<img class="object-cover lg:min-h-full" src="/assets/images/aquacity-{{ 'LANG-CODE' | translate }}.jpg" alt="{{ 'PRICING-TITLE' | translate }}">
		</div>
	</div>
</div>

<div class="relative bg-gray-900 max-w-screen-sm lg:max-w-screen-2xl mx-auto py-8 px-8 lg:px-16">
	<div class="grid grid-cols-12">
		<div class="col-span-12 lg:col-span-8 lg:pr-6">
			<div class="divider-style">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
	      	<div class="mt-6">
	        	<h3 class="text-xl font-extrabold uppercase text-white pt-2 pb-8">
					{{ 'BLOG-TITLE' | translate }}
	        	</h3>
	        </div>
		</div>
	</div>
	<div class="relative py-5">
		<div class="grid grid-cols-12 gap-5 md:gap-10 lg:gap-20">
			<div *ngFor="let blog of blogs" class="col-span-12 md:col-span-6 lg:col-span-4 pb-10 lg:pb-0">
				<div class="relative">
					<!--
					<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug" class="text-white">
						<h2 class="text-white absolute bottom-0 left-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-xl text-white">
			        		
								{{ blog.title }}
							
			        	</h2>
			        </a>
			    -->
					<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
						{{ blog.blog_category.title }}
					</div>
					<a class="overflow-hidden block" [class.h-56]="i != 0" [class.h-96]="i == 0" [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
						<img class="h-full w-full object-cover object-cover-link z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
					</a>
				</div>

		      	<div class="mt-6">
		        	<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
		        		<h2 class="py-3 text-xl text-white">
							{{ blog.title }}
		        		</h2>
		        	</a>
		        	<div class="text-justify" [innerHtml]=" blog.intro_text">
						
					</div>
		      	</div>
		    </div>
		    <div class="col-span-12">
		    	<div class="pb-10 w-full text-center">
					<a class="btn btn-outline" [routerLink]="'MENU-LINK-3' | translate">
						{{ 'EXPLORE-CTA' | translate }}
					</a>
				</div>
		    </div>
		</div>
	</div>
</div>