import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FormsService } from './../../../services/forms.service';
import { AlertService } from './../../../services/alert.service';

@Component({
  	selector: 'app-contact-form',
  	templateUrl: './contact-form.component.html',
  	styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

	form = new FormGroup({
		name: new FormControl(null, [
			Validators.required
		]),
		email: new FormControl(null, [
			Validators.required,
			Validators.email,
		]),
		message: new FormControl(null, [
			Validators.required
		]),
		terms: new FormControl(null, [
			Validators.required
		]),
        address_new_city: new FormControl(null, [
            
        ]),
	});

  	constructor(
  		private formsService: FormsService,
  		private alertService: AlertService,
  	) { }

  	ngOnInit(): void {
  		
  	}

  	onSubmit() {
  		console.log('on submit main form');
  	
  		this.formsService.submitContactForm(this.form.value).then(
  			(response) => {
  				let alert = {
  					'title':'Formulár bol úspešne odoslaný',
  					'text': 'Ďakujeme, že ste nás kontaktovali. Vaše správe sa budeme venovať v čo najkratšom možnom čase.',
  					'type': 'success'
  				};
  				this.alertService.alert(alert);
	  			this.form.reset();
  			}, (error) => {
  				let alert = {
  					'title':'Pri odosielaní sa vyskytla chyba',
  					'text': 'Skúste formulár odoslať neskôr, prosím.',
  					'type': 'error'
  				};
  				this.alertService.alert(alert);
  			}

  		);
  	}

}
