import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apartment-index',
  templateUrl: './apartment-index.component.html',
  styleUrls: ['./apartment-index.component.scss']
})
export class ApartmentIndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
