import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  	providedIn: 'root'
})
export class BlogService {

  	constructor(
  		private http: HttpClient,
  		private env: EnvService,
  	) { }

  	getItemsList(lang:any,categoryId:any, limit: number): Observable<any> {
        return this.http.get(this.env.apiUrl + 'blogs?locale='+lang+'&category='+categoryId+'&limit='+limit);
    }

    getCategoriesList(lang: any): Observable<any> {
        return this.http.get(this.env.apiUrl + 'blog-categories?locale='+lang);
    }

  	getItem(slug: string, lang: string){
  		return this.http.get(this.env.apiUrl + 'blogs/' + slug + '?locale='+lang);
  	}
}
