import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  	providedIn: 'root'
})
export class TestimonialService {

  	constructor(
  		private http: HttpClient,
  		private env: EnvService,
  	) { }

  	getItems(lang:any): Observable<any> {
        return this.http.get(this.env.apiUrl + 'testimonials?locale='+lang);
    }
}