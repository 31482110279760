import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { NgxSpinnerService } from "ngx-spinner";

import { EnvService } from './env.service';



@Injectable({
  	providedIn: 'root'
})
export class FormsService {
    

  	constructor(
  		private http: HttpClient,
  		private env: EnvService,
  		private spinner: NgxSpinnerService,
  	) { }

    
  	submitContactForm(data){
  		this.spinner.show();
  		const promise = new Promise((resolve, reject) => {
            this.http.post(
                this.env.apiUrl + 'contact/create',
                {
                    email: data.email,
                    name: data.name,
                    message: data.message,
                    address_new_city: data.address_new_city,
                }
            ).toPromise().then((res: any) => {
            	this.spinner.hide();
                resolve(res);
            },
            err => {
                // Error
                this.spinner.hide();
                console.log(err);
                reject(err);
            });
        });
        return promise;
  	}

  	submitQuoteForm(data){
  		console.log(data);
  		this.spinner.show();
  		const promise = new Promise((resolve, reject) => {
            this.http.post(
                this.env.apiUrl + 'quote/create',
                {
                    email: data.email,
                    name: data.name,
                    message: data.message,
                    area: data.area,
                    location: data.location,
                    frequency: data.frequency,
                    object_type: data.object_type,
                    address_new_city: data.address_new_city,
                }
            ).toPromise().then((res: any) => {
            	this.spinner.hide();
                resolve(res);
            },
            err => {
                // Error
                this.spinner.hide();
                console.log(err);
                reject(err);
            });
        });
        return promise;
  	}
}
