import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from './../../../services/env.service';
import { PackageService } from './../../../services/package.service';
import { LangService } from './../../../services/lang.service';

@Component({
  	selector: 'app-package-item',
  	templateUrl: './package-item.component.html',
  	styleUrls: ['./package-item.component.scss']
})
export class PackageItemComponent implements OnInit {

	item: any;

	mainContentObj = [];

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
        private langService: LangService,
        private translate: TranslateService,
        private packageService: PackageService,
        private activatedRoute: ActivatedRoute,
  	) { }

  	ngOnInit(): void {
  		this.activatedRoute.params.subscribe(parameter => {
	      	this.translate.get('LANG-CODE').subscribe((lang: string) => {		
		      	this.packageService.getItem(parameter.slug, lang).subscribe(
		  			(res:any) => {
		                this.item = res.data[lang];
		                this.findImg(this.item.full_text);
		                this.titleService.setTitle(this.item.title + ' - ' + this.env.siteNameConstant + this.env.siteNameSlogan);
				  		this.metaService.addTags([
				      		{ name: 'keywords', content: 'lawis apartments, apartmany lawis, ubytovanie poprad, vikendove apartmany, luxusne apartmany, apartmany poprad,' },
				      		{ name: 'description', content: 'Luxusné víkendové apartmány v Poprade' },
				    	]);
				    	this.langService.langUrl.sk = '/sk/balicky/'+res.data['sk'].slug;
				    	this.langService.langUrl.en = '/en/packages/'+res.data['en'].slug;
		            },
		            err => {
		                console.log(err);
		            },
		            () => {
		            }
		       	);
		    });
	    });
  	}

  	findImg(html) {
  		let self = this;
  		let img;
  		const sourceStr = html
		const searchStr = '<img';
		const indexes = [...sourceStr.matchAll(new RegExp(searchStr, 'gi'))].map(a => a.index);

		const searchImgEnd = '/>';
		const imgEndIndexes = [...sourceStr.matchAll(new RegExp(searchImgEnd, 'gi'))].map(a => a.index+2);

		const searchImgSrc = 'src';

		let lastIndex = 0;
		let rightOffset = 0;
		indexes.forEach((currIndex: any, index) => {
			imgEndIndexes.forEach((currEndIndex: any) => {

			});
			this.mainContentObj.push({
				type: 'text',
				content: html.substring(lastIndex, currIndex)
			});
			let imageObject = html.substring(currIndex, imgEndIndexes[index]);
			let imageSrc = imageObject.substring(imageObject.indexOf("src")+5, imageObject.indexOf("alt")-2);
			this.mainContentObj.push({
				type: 'image',
				content: imageSrc
			});
			lastIndex = imgEndIndexes[index];

			if(index === indexes.length-1){
				this.mainContentObj.push({
					type: 'text',
					content: html.substring(imgEndIndexes[index], html.length)
				});
			}

		});
		console.log(this.mainContentObj);
	}

}

