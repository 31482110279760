import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  	providedIn: 'root'
})
export class EnvService {

	siteNameConstant = 'Lawis Apartments';
	siteNameSlogan = ' | luxusné víkendové apartmány v Poprade';

	//apiUrl = 'http://lawis-api.test/api/';
	apiUrl = 'https://api.lawisapartments.sk/api/';

	langLink = {
		'sk': '',
		'en': '',
	}

	activeLocale = 'sk';

  	constructor(
  		private translate: TranslateService,
  		private router: Router,
  	) { }

  	setLocale(locale){
  		this.activeLocale = locale;
  		this.translate.use(locale);
  	}
}
