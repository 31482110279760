import { Component, Directive, HostListener, OnInit, OnDestroy, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationStart, NavigationEnd, Event as NavigationEvent } from '@angular/router';
import { NgbModal, NgbModalConfig, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { NgxSpinnerService } from "ngx-spinner";
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DomSanitizer } from '@angular/platform-browser';

import { LangService } from './services/lang.service';
import { AlertService } from './services/alert.service';

import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { EnvService } from './services/env.service';

@Component({
  	selector: 'app-root',
  	templateUrl: './app.component.html',
  	styleUrls: ['./app.component.scss'],
    animations: [
        trigger('collapse', [
            state('open', style({ 
                //marginTop: '0',
                transform: 'translateX(0)'
                //opacity: '1',
            })),
            state('closed', style({ 
                //marginTop: '-200px',
                transform: 'translateX(100%)'
                //opacity: '0',
            })),
            transition('open => closed', [
                animate('0.3s ease-in')
            ]),
            transition('closed => open', [
                animate('0.3s ease-out')
            ]),
        ])
    ]
})

export class AppComponent implements OnInit {
    title = 'Lawis';
    event$

    activeLocale = 'sk';

    @ViewChild('sidenav') sidenav: MatSidenav;

    @ViewChild("mainContent")private mainContentDiv!: ElementRef<HTMLElement>;

    currentYear = '';

    menuActive = false;

    cookiesAccepted = true;

    mapsUrl = 'https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDOYAKgkktl_IkFBJY1dfbwtVHIjnf1KVg&center=49.04026716449492,20.19170723499091&zoom=10&format=png&maptype=roadmap&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=640x640';

    constructor(
        private router: Router,
        private translate: TranslateService,
        private env: EnvService,
        private langService: LangService,
        private cookieService: CookieService,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private gtmService: GoogleTagManagerService,
        public sanitizer: DomSanitizer,
        private alertService: AlertService
    ) {

        this.event$=this.router.events.subscribe(
            (event: NavigationEvent) => {
                if(event instanceof NavigationEnd) {
                    if(this.sidenav){
                        this.sidenav.close();
                    }

                    this.modalService.dismissAll();

                    if(event.url.substring(1, 3).length == 2){
                        this.setLocale(event.url.substring(1, 3));
                    }
                }
            });
    }

    ngOnInit(){
        this.setSidenav(this.sidenav);
        this.activeLocale = this.env.activeLocale;

        this.currentYear = new Date().getFullYear().toString();

        if(!this.cookieService.get("accept_cookies")){
            this.cookiesAccepted = false;
        }

        this.alertService.toggleMenuAction.subscribe((data) => {
            console.log(data);
            if(data === false){
                this.menuActive = false;
            }else {
                this.toggleMenu();
            }
        });

        this.gtmService.addGtmToDom();

    }

    onActivate(_event: any): void {
        // Scrolling back to the top
        let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
        this.menuActive = false;
    }

    ngAfterViewInit(){
        /*
        let _this = this;
        setTimeout(function(){
            _this.setLocale(this.activeLocale);
        }, 500);
        */
    }

    acceptCookies(){
        this.cookieService.set('accept_cookies', 'true');
        this.cookiesAccepted = true;
        this.modalService.dismissAll();
    }

    openModal(target){
        this.modalService.open(target, { centered: true, windowClass: 'modal-open' });
    }

    ngOnDestroy(){
        this.event$.unsubscribe();
    }

    setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;
    }

    setLocale(locale){
        //console.log(locale);
        this.activeLocale = locale;
        this.env.setLocale(locale);
        //this.langSwitch.setLang(locale);
    }

    changeLang(lang){
        this.langService.changeLanguage(lang);
    }

    toggleMenu(): void {
        //console.log(this.menuActive);
        this.menuActive = !this.menuActive;
    }

    scrollToTop(){
        document.getElementById("topBlock").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
        });
    }
}
