import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

import { EnvService } from './../../services/env.service';


@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

	switchToLang = 'en';
	switchToUrl = '';

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
  		private env: EnvService,
  		private router: Router,
  	) { }

  	ngOnInit(): void {
  	
  	}

  	setLang(lang){
  		if(lang == 'sk'){
  			this.router.navigate([this.env.langLink.sk]);
  		}else {
  			this.router.navigate([this.env.langLink.en]);
  		}
  	}

}
