<ng-container *ngFor="let introblog of blogs; let e = index">
	<div *ngIf="e === 0" class="relative bg-gray-900 max-w-screen-2xl mx-auto mb-12">
		<div class="grid grid-cols-12 pt-5 relative z-10">
			<div class="col-span-12 md:col-span-8 lg:col-span-6 px-8 md:px-16 pt-48 pb-20 fade-in-content">
				<!--
				<div class="divider-style mb-5">
					<div class="short w-24 h-2 bg-white"></div>
					<div class="long w-full h-px bg-white"></div>
				</div>
			-->
				<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ introblog.slug">
					<h1 class="text-gray-100" [innerHtml]='introblog.title'>
						
					</h1>
				</a>
				<p class="pt-7 pb-5 text-gray-300" [innerHtml]='introblog.intro_text'>
				</p>
				<p class="pt-5 pb-5 text-gray-300">
					<a class="btn btn-outline" [routerLink]="('MENU-LINK-3' | translate) + '/'+ introblog.slug">
						{{ "MORE" | translate }}
					</a>
				</p>
			</div>
			<div class="col-span-12 px-8 md:px-16 pt-8 fade-in-content">
				<div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
					<a *ngFor="let item of categories; let i = index" class="blog-category-link flex py-5 items-center content-center justify-center bg-black bg-opacity-50 text-white border-black cursor-pointer transition ease-out duration-500" [class.border-r-0]="i == 3" [class.border-r]="i != 3" (click)="setCategory(item.id, scrollTarget)" [class.bg-pink-700]="selectedCategoryId == item.id">
						<div class="px-3 w-16">
							<div class="relative overflow-hidden">
								<img class="w-full relative" src="{{ item.image }}" alt="{{ item.title }}">
							</div>
						</div>
						<div class="w-1/2 text-center">
							<div class="relative overflow-hidden">
								<h3>
									{{ item.title }}
								</h3>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="absolute w-full h-full top-0 z-0 overflow-hidden">
			<img class="h-full w-full object-cover section-cover" src="{{ introblog.image }}" alt="{{ introblog.title }}">
			<div class="absolute inset-0 bg-gray-500" style="mix-blend-mode: multiply;"></div>
		</div>
	</div>
</ng-container>
<div class="relative bg-gray-900 lg:max-w-screen-2xl mx-auto px-8 md:px-16" #scrollTarget>
	<div class="relative max-w-screen-lg mx-auto py-10 article-detail">
		<div>
			<ng-container *ngIf="blogs.length === 0">
				<div class="grid grid-cols-12 gap-5 md:gap-10 lg:gap-20">
					<div class="col-span-12 mb-12 mt-12">
						{{ 'EMPTY-CONTENT' | translate }}
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="blogs.length !== 0">
				<div class="grid grid-cols-2 gap-5 md:gap-10 lg:gap-20">
					<ng-container *ngFor="let blog of blogs; let i = index">
						<div *ngIf="i !== 0" class="col-span-2 md:col-span-1">
							<div class="relative">
								<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
									{{ blog.blog_category.title }}
								</div>
								<a class="overflow-hidden block h-60" [class.lg:h-96]="i == 0" [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
									<img class="h-full w-full object-cover object-cover-link z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
								</a>
							</div>
						</div>
				      	<div *ngIf="i !== 0" class="mt-5 col-span-2 md:col-span-1">
				      		<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
				        		<h2 class="py-3 text-xl text-white">
									{{ blog.title }}
				        		</h2>
				        	</a>
				        	<div class="text-justify mb-3" [innerHtml]="blog.intro_text">
								
							</div>
				      	</div>
				    </ng-container>
				</div>
			</ng-container>
			<div *ngIf="totalBlogs > 10 && limit !== 0" class="text-center pt-20 cursor-pointer">
				<a class="btn btn-outline" (click)="getAllArticles()">
					{{ 'EXPLORE-CTA' | translate }}
				</a>
			</div>
		</div>
	</div>
	<div *ngIf="relatedBlogs" class="relative max-w-screen-lg mx-auto pt-10">
		<div>
			<div *ngIf="relatedBlogs.length" class="grid grid-cols-12 gap-5 md:gap-6 py-10 lg:gap-10">
				<div class="col-span-12">
					<div class="divider-style">
						<div class="short w-24 h-2 bg-white"></div>
						<div class="long w-full h-px bg-white"></div>
					</div>
			      	<div class="mt-8">
			        	<h2 class="text-3xl font-extrabold tracking-tight text-white">
							{{ "MOST-FAVORITE" | translate }}
			        	</h2>
			      	</div>
				</div>
				<div *ngFor="let blog of relatedBlogs; let i = index" class="col-span-12 md:col-span-6 lg:col-span-4">
					<ng-container *ngIf="i < 3">
						<div class="relative">
							<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
								{{ blog.blog_category.title }}
							</div>
							<a class="overflow-hidden block h-60" [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
								<img class="w-full h-full object-cover object-cover-link z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
							</a>
						</div>

				      	<div class="mt-6">
				        	<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
				        		<h2 class="py-3 text-xl text-white">
									{{ blog.title }}
				        		</h2>
				        	</a>
				        	<div class="text-justify" [innerHtml]=" blog.intro_text">
								
							</div>
				      	</div>
				    </ng-container>
			    </div>
			    <!--
			    <div class="col-span-12">
			    	<div class="pr-16 pt-5 w-full text-center">
						<a class="btn" href="{{ 'MENU-LINK-3' | translate }}">
							{{ 'EXPLORE-CTA' | translate }}
						</a>
					</div>
			    </div>
			-->
			</div>
		</div>
	</div>
</div>
