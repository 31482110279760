import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  	selector: 'app-custom-icons',
  	templateUrl: './custom-icons.component.html',
  	styleUrls: ['./custom-icons.component.scss']
})
export class CustomIconsComponent implements OnInit {

	@Input() classes = '';
	@Input() iconSlug = '';

  	constructor() { }

  	ngOnInit(): void {

  	}

}
