import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TestimonialService } from './../../services/testimonial.service';

import { SwiperOptions } from 'swiper';

//import { TESTIMONIALS } from './../../mock-data/mock-data';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

	testimonials = [];

	testimonialsActivate = false;

	config: SwiperOptions = {
    	//pagination: { el: '.swiper-pagination', clickable: true },
    	navigation: {
      		nextEl: '.swiper-button-next',
      		prevEl: '.swiper-button-prev'
    	},
    	spaceBetween: 4,
    	loop: true,
    	autoHeight: true,
        /*
    	autoplay: {
      		delay: 6000,
      		disableOnInteraction: true
    	},
        */
        slidesPerView: 1,
        slidesPerGroup: 1,
        speed: 800,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
  // Responsive breakpoints
  
        breakpoints: {
            1024: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                autoHeight: true,
            },
            /*
    // when window width is >= 320px
            320: {
                slidesPerView: 2,
                spaceBetween: 20
            },
    // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 30
            },
    // when window width is >= 640px
            640: {
                slidesPerView: 2,
                spaceBetween: 10
            }
            */
        }
        
  	};

  	constructor(
        private translate: TranslateService,
        private testimonialService: TestimonialService,
    ) { }

  	ngOnInit(): void {
  		
  		this.getTestimonials();
		
  	}

  	ngAfterViewChecked() {
        
  	}

    getTestimonials(){
        let self = this;
        this.translate.get('LANG-CODE').subscribe((lang: string) => {        
              this.testimonialService.getItems(lang).subscribe(
                (res:any) => {
                    self.testimonials = res.data;
                    if(self.testimonials.length){
                        setTimeout(() => self.testimonialsActivate = true, 400);
                    }
                },
                err => {
                    console.log(err);
                },
                () => {
                }
            );
        });
    }

}
