import { Component, OnInit, Inject } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EnvService } from './../../../services/env.service';
import { BlogService } from './../../../services/blog.service';
import { LangService } from './../../../services/lang.service';

@Component({
  	selector: 'app-blog-index',
  	templateUrl: './blog-index.component.html',
  	styleUrls: ['./blog-index.component.scss']
})
export class BlogIndexComponent implements OnInit {

	blogs = [];
	categories = [];
	selectedCategoryId = '';

	limit = 10;

	totalBlogs = 0;
	relatedBlogs = [];

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
        private langService: LangService,
        private blogService: BlogService,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        @Inject(DOCUMENT) private doc: any
  	) { }

  	ngOnInit(): void {
  		this.translate.get('MENU-TITLE-3').subscribe(
            value => {
            	this.titleService.setTitle(value + " - " + this.env.siteNameConstant + this.env.siteNameSlogan);
            }
        )
  		
  		this.metaService.addTags([
      		{ name: 'keywords', content: 'lawis apartments, apartmany lawis, ubytovanie poprad, vikendove apartmany, luxusne apartmany, apartmany poprad,' },
      		{ name: 'description', content: 'Luxusné víkendové apartmány v Poprade' },
    	]);
    	this.langService.langUrl.sk = '/sk/blog';
    	this.langService.langUrl.en = '/en/blog';

    	let link: HTMLLinkElement = this.doc.createElement('link');
      	link.setAttribute('rel', 'canonical');
      	this.doc.head.appendChild(link);
      	this.translate.get('LANG-CODE').subscribe((lang: string) => {
      		link.setAttribute('href', 'https://www.lawisapartments.sk/'+lang+'/blog');
      	});

    	this.getCategories();

    	this.activatedRoute.queryParams.subscribe(params => {
	        if(params.category){
	        	this.selectedCategoryId = params.category;
	        }
	        this.getItems(this.limit);
		});
    	

    	
  	}

  	getItems(limit){
  		this.translate.get('LANG-CODE').subscribe((lang: string) => {		
	  		this.blogService.getItemsList(lang, this.selectedCategoryId, limit).subscribe(
	            (res:any) => {
	                this.blogs = res.data.articles;
	                this.totalBlogs = res.data.total;
	                this.relatedBlogs = res.data.related;
	            },
	            err => {
	                console.log(err);
	            },
	            () => {
	            }
	        );
        });
  	}

  	getCategories(){
  		this.translate.get('LANG-CODE').subscribe((lang: string) => {		
	  		this.blogService.getCategoriesList(lang).subscribe(
	            (res:any) => {
	                this.categories = res.data;
	            },
	            err => {
	                console.log(err);
	            },
	            () => {
	            }
	        );
        });
  	}

  	setCategory(categoryId, scrollTarget){
  		this.selectedCategoryId = categoryId;
  		/*
  		this.getItems(this.limit);

  		document.getElementById("topBlock").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "nearest"
        });
        */

        this.router.navigate([], {
        	queryParams: { category: this.selectedCategoryId }
		});
/*
        this.activatedRoute.queryParams
	      .subscribe(params => {
	        console.log(params);
	      }
	    );
	    */
  	}

  	scroll(el: HTMLElement) {
	    el.scrollIntoView({behavior: 'smooth'});
	}

	getAllArticles(){
		this.limit = 0;
		this.getItems(0);
	}

}
