import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { FormsService } from './../../../services/forms.service';
import { AlertService } from './../../../services/alert.service';

@Component({
  	selector: 'app-sign-up-form',
  	templateUrl: './sign-up-form.component.html',
  	styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {

	selectedFreqency = '';

	objectTypes = [
		{ 
			id: 'rodinny-dom',
			title: "Rodinný dom",
		},
		{ 
			id: 'byt',
			title: "Byt",
		},
		{ 
			id: 'bytovy-dom',
			title: "Bytový dom",
		},
		{ 
			id: 'kancelarske-priestory',
			title: "Kancelárske priestory",
		},
		{ 
			id: 'obchodne-priestory',
			title: "Obchodné priestory",
		},
		{ 
			id: 'ine',
			title: "Iné",
		}
	];

  	form = new FormGroup({
		name: new FormControl(null, [
			Validators.required
		]),
		email: new FormControl(null, [
			Validators.required,
		]),
		message: new FormControl(null, [
			Validators.required
		]),
		terms: new FormControl(null, [
			Validators.required
		]),
		area: new FormControl(null, [
			Validators.required
		]),
		location: new FormControl(null, [
			Validators.required
		]),
		frequency: new FormControl(null, [
			Validators.required
		]),
		object_type: new FormControl(null, [
			Validators.required
		]),
		address_new_city: new FormControl(null, [
            
        ]),
  	});

  	constructor(
  		private formsService: FormsService,
  		private alertService: AlertService,
  	) { }

  	ngOnInit(): void {
  	
  	}

  	onSubmit() {
  		console.log(this.form.value);
  		this.formsService.submitQuoteForm(this.form.value).then(
  			(response) => {
  				let alert = {
  					'title':'Formulár bol úspešne odoslaný',
  					'text': 'Ďakujeme, že ste nás kontaktovali. Vaše správe sa budeme venovať v čo najkratšom možnom čase.',
  					'type': 'success'
  				};
  				this.alertService.alert(alert);
	  			this.form.reset();
  			}, (error) => {
  				let alert = {
  					'title':'Pri odosielaní sa vyskytla chyba',
  					'text': 'Skúste formulár odoslať neskôr, prosím.',
  					'type': 'error'
  				};
  				this.alertService.alert(alert);
  			}

  		);
  	}
}