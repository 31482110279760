import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from './../../../services/env.service';
import { PackageService } from './../../../services/package.service';
import { LangService } from './../../../services/lang.service';

@Component({
  	selector: 'app-package-index',
  	templateUrl: './package-index.component.html',
  	styleUrls: ['./package-index.component.scss']
})
export class PackageIndexComponent implements OnInit {

	packages = [];

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
        private langService: LangService,
        private packageService: PackageService,
        private translate: TranslateService,
  	) { }

  	ngOnInit(): void {
  		this.translate.get('MENU-TITLE-2').subscribe(
            value => {
            	this.titleService.setTitle(value + " - " + this.env.siteNameConstant + this.env.siteNameSlogan);
            }
        )
  		
  		this.metaService.addTags([
      		{ name: 'keywords', content: 'lawis apartments, apartmany lawis, ubytovanie poprad, vikendove apartmany, luxusne apartmany, apartmany poprad,' },
      		{ name: 'description', content: 'Luxusné víkendové apartmány v Poprade' },
    	]);
    	this.langService.langUrl.sk = '/sk/balicky';
    	this.langService.langUrl.en = '/en/packages';

    	this.getItems();
  	}

  	getItems(){
  		this.translate.get('LANG-CODE').subscribe((lang: string) => {		
	  		this.packageService.getItemsList(lang).subscribe(
	            (res:any) => {
	                console.log(res.data);
	                this.packages = res.data;
	            },
	            err => {
	                console.log(err);
	            },
	            () => {
	            }
	        );
        });
  	}

}

