import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from './../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

	@Input() id = 'default-alert';
    @Input() fade = true;
    @Input() message = '';
    @Input() display = false;

    alertSubscription: Subscription;

  	constructor(
  		private alertService: AlertService
  	) { }

  	ngOnInit(): void {
  		this.alertSubscription = this.alertService.onAlert(this.id).subscribe(alert => {
			this.message = alert.title + ". " + alert.text;
			this.id = alert.type;
			this.toggleAlertOn();
       	});
  	}

  	toggleAlertOn(){
  		let self = this;
  		self.display = true;
  		setTimeout(function(){
  			self.display = false;
  			self.message = '';
  			self.id = 'default-alert';
  		},
  			8000
  		);
  	}

}
