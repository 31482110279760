<div class="">   
<!-- 
    <a class="navlink" [routerLink]="'MENU-LINK-1' | translate" [routerLinkActive]="'active'">{{ "MENU-TITLE-1" | translate }}</a>
-->
    <a class="navlink" [routerLink]="'MENU-LINK-2' | translate" [routerLinkActive]="'active'" (click)="closeMenu()">{{ "MENU-TITLE-2" | translate }}</a>

    <a class="navlink" [routerLink]="'MENU-LINK-3' | translate" [routerLinkActive]="'active'" (click)="closeMenu()">{{ "MENU-TITLE-3" | translate }}</a>

    <a class="navlink" (click)="scrollToContact()">{{ "MENU-TITLE-4" | translate }}</a>
    
</div>
<div class="mt-4 mb-6">
    <a class="btn" href="'RESERVATION-LINK' | translate" (click)="closeMenu()">{{ "RESERVATION-TITLE" | translate }}</a>
</div>