import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe } from '@angular/common'; 

import { registerLocaleData } from '@angular/common';
import localeSk from '@angular/common/locales/sk';
registerLocaleData(localeSk);
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from 'src/app/services/token-interceptor.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HttpHandler, HttpHeaders } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule, MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';

import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxSpinnerModule } from "ngx-spinner";

import { HomePageComponent } from './pages/home-page/home-page.component';

import { MainNavComponent } from './components/main-nav/main-nav.component';
import { SignUpFormComponent } from './components/forms/sign-up-form/sign-up-form.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';

import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ContactFormComponent } from './components/forms/contact-form/contact-form.component';
import { AlertComponent } from './components/alert/alert.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CustomIconsComponent } from './components/custom-icons/custom-icons.component';
import { PackageIndexComponent } from './pages/packages/package-index/package-index.component';
import { PackageItemComponent } from './pages/packages/package-item/package-item.component';
import { BlogIndexComponent } from './pages/blogs/blog-index/blog-index.component';
import { BlogItemComponent } from './pages/blogs/blog-item/blog-item.component';
import { ApartmentIndexComponent } from './pages/apartments/apartment-index/apartment-index.component';
import { ApartmentItemComponent } from './pages/apartments/apartment-item/apartment-item.component';
import { GeneralTermsComponent } from './pages/general-terms/general-terms.component';
import { AccomodationTermsComponent } from './pages/accomodation-terms/accomodation-terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomePageComponent,
        MainNavComponent,
        SignUpFormComponent,
        LangSwitcherComponent,
        TestimonialsComponent,
        ErrorPageComponent,
        ContactPageComponent,
        ContactFormComponent,
        AlertComponent,
        CustomIconsComponent,
        PackageIndexComponent,
        PackageItemComponent,
        BlogIndexComponent,
        BlogItemComponent,
        ApartmentIndexComponent,
        ApartmentItemComponent,
        GeneralTermsComponent,
        AccomodationTermsComponent,
        PrivacyPolicyComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            },
            defaultLanguage: 'sk'
        }),
        AppRoutingModule,
        NgbModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatCardModule,
        MatExpansionModule,
        NgxUsefulSwiperModule,
        NgxSpinnerModule,
        FontAwesomeModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        PhotoGalleryModule.forRoot({
            defaultOptions: {
                loop: false,
                arrowEl: true,
                arrowKeys: true,
                preload: [1,1],
                //indexIndicatorSep: '-',
                closeOnScroll: true,
            }
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: TokenInterceptorService, 
            multi: true
        },
        { 
            provide: 'googleTagManagerId',  
            useValue: 'GTM-PWXRGVQ'
        },
        HttpClient,
        MatSidenav,
        CookieService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent],
    exports: [
        TranslateModule
    ]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        // Add an icon to the library for convenient access in other components
        library.addIconPacks(fas, far);
    }
}
