import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { EnvService } from './../../services/env.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
  	) { }

  	ngOnInit(): void {
  		this.titleService.setTitle('Kontakt | ' + this.env.siteNameConstant + this.env.siteNameSlogan);
  		this.metaService.addTags([
      		{ name: 'keywords', content: '' },
      		{ name: 'description', content: 'Kontakt | ' + this.env.siteNameConstant + this.env.siteNameSlogan },
    	]);
    	this.env.langLink.sk = '/sk/kontakt';
    	this.env.langLink.en = '/en/contact';
  	}

}
