import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { EnvService } from './env.service';
import { AlertComponent } from './../components/alert/alert.component';

@Injectable({
  	providedIn: 'root'
})
export class AlertService {

    @Output() toggleMenuAction = new EventEmitter();

	private subject = new Subject<any>();
    private defaultId = 'default-alert';

  	constructor() { }

  	onAlert(id = this.defaultId): Observable<any> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }
/*
    // convenience methods
    success(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Success, message }));
    }

    error(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Error, message }));
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }
*/
    // main alert method    
    alert(alert) {
    	//console.log(alert);
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

}
