import { Injectable } from '@angular/core';
import { Routes, Router } from '@angular/router';


@Injectable({
  	providedIn: 'root'
})
export class LangService {

	langUrl = {
		sk: '',
		en: '',
	};

  	constructor(
  		private router: Router,
  	) { }

  	setLanguageMutationUrl(url){

  	}

  	changeLanguage(lang){
  		this.router.navigateByUrl(this.langUrl[lang]);
  	}
}
