<div class="relative bg-gray-900 max-w-screen-2xl mx-auto mb-12">
	<div class="grid grid-cols-12 py-5 relative z-10">
		<div class="col-span-12 md:col-span-8 lg:col-span-6 px-8 md:px-16 pt-48 pb-40 fade-in-content">
			<div class="divider-style mb-5">
				<div class="short w-24 h-2 bg-white"></div>
				<div class="long w-full h-px bg-white"></div>
			</div>
			<h1 class="text-gray-100 pb-5" [innerHtml]='"OFFER-TITLE" | translate'>
				
			</h1>
			<p class="pt-2 pb-5 text-gray-300" [innerHtml]='"OFFER-TEXT" | translate'>
			</p>
		</div>
	</div>
	<div class="absolute w-full h-full top-0 z-0 overflow-hidden">
		<img class="h-full w-full object-cover section-cover" src="/assets/images/022.jpg" alt="{{ 'BLOG-HEADLINE' | translate }}">
		<div class="absolute inset-0 bg-gray-500" style="mix-blend-mode: multiply;"></div>
	</div>
</div>
<div class="relative bg-gray-900 lg:max-w-screen-2xl mx-auto py-8 px-8 md:px-16">
	<div class="relative max-w-screen-lg mx-auto py-10 article-detail">
		<div>
			<ng-container *ngIf="packages.length === 0">
				<div class="grid grid-cols-12 gap-5 md:gap-10 lg:gap-20">
					<div class="col-span-12 mb-12 mt-12">
						{{ 'EMPTY-CONTENT' | translate }}
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="packages.length !== 0">
				<div class="grid grid-cols-2 gap-5 md:gap-10 lg:gap-20 items-center">
					<ng-container *ngFor="let blog of packages; let i = index">
						<div class="col-span-2 md:col-span-1">
							<div class="relative">
								<a class="overflow-hidden block h-60" [routerLink]="('MENU-LINK-2' | translate) + '/'+ blog.slug">
									<img class="h-full w-full object-cover object-cover-link z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
								</a>
							</div>
						</div>
				      	<div class="col-span-2 md:col-span-1">
				      		<a [routerLink]="('MENU-LINK-2' | translate) + '/'+ blog.slug">
				        		<h2 class="py-3 text-xl text-white">
									{{ blog.title }}
				        		</h2>
				        	</a>
				        	<div class="text-justify mb-3" [innerHtml]="blog.intro_text">
								
							</div>
				      	</div>
				    </ng-container>
				</div>
			</ng-container>
		</div>
	</div>
	<!--
	<div class="grid grid-cols-12 gap-5 md:gap-10 lg:gap-20">
			<div *ngFor="let blog of packages; let i = index" class="col-span-12 md:col-span-6 lg:col-span-6 mb-12">
				<div class="relative">
					<a [routerLink]="('MENU-LINK-2' | translate) + '/'+ blog.slug" class="text-white">
						<h2 class="text-white absolute bottom-0 left-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-xl text-white">
			        		
								{{ blog.title }}
							
			        	</h2>
		        	</a>
					<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
						{{ blog.blog_category.title }}
					</div>
					<a class="overflow-hidden h-80 lg:h-96 block" [routerLink]="('MENU-LINK-2' | translate) + '/'+ blog.slug">
						<img class="h-full w-full object-cover" src="{{ blog.image }}" alt="{{ blog.title }}">
					</a>
				</div>

		      	<div class="mt-6">
		        	
		        	<div class="text-justify" [innerHtml]=" blog.intro_text">
						
					</div>
		      	</div>
		    </div>
	</div>
-->
</div>