//import { Product } from '../product';

export const APARTMENTS = {
    'sk': [
        { 
            id: 1, 
            title: 'Apartmán JUH', 
            slug: 'apartman-juh',
            text: `
                  Z obrovského panoramatického balkóna si môžete vychutnať očarujúci výhľad na Kráľovu hoľu. Apartmán JUH s kapacitou 
                  pre max. 6 ľudí je vybavený modernou, plne zariadenou kuchyňou (umývačka riadu, chladnička s mrazničkou, indukčná 
                  varná doska, rúra na pečenie, mikrovlnná rúra, kávovar Tchibo, kanvica), obývacou izbou a dvomi spálňami. Ďalej 
                  disponuje súkromným WC, kúpeľňou a práčkou. Apartmán zahŕňa satelitnú TV s plochou obrazovkou a streamovacou službou 
                  NETFLIX, bezplatnú WiFi, relaxačnú hojdačku a terasu, vybavenú exteriérovým sedením, do ktorej vstup je možný 
                  zo spální a obývacieho priestoru. K dispozícii je aj samostatný úložný priestor pre vaše lyže, snowboardy, bicykle 
                  a iné. 
                `,
            features: [
                {
                    icon: 'area',
                    text: '85 m2'
                },
                {
                    icon: 'wifi',
                    text: 'Wifi'
                },
                {
                    icon: 'parking',
                    text: 'Parkovanie'
                },
                {
                    icon: 'bed',
                    text: 'Veľká posteľ'
                },
                {
                    icon: 'door',
                    text: '2x izba'
                },
                {
                    icon: 'netflix',
                    text: 'Netflix'
                },
                {
                    icon: 'pracka',
                    text: 'Práčka'
                },
                {
                    icon: 'eye',
                    text: 'Výhľad na Nízke Tatry'
                },
                {
                    icon: 'terrace',
                    text: 'Terasa'
                },
            ],
            mainImage: '/assets/images/juh/J1-min.jpg',
            secondaryImage: '/assets/images/juh/J2-min.jpg',
            images: [
                '/assets/images/juh/J3.jpg',
                '/assets/images/juh/J6.jpg',
                '/assets/images/juh/J4.jpg',
                '/assets/images/juh/J5.jpg',
                '/assets/images/juh/J7.jpg',
                '/assets/images/juh/J8.jpg',
                '/assets/images/juh/J1.jpg',
                '/assets/images/juh/J2.jpg'
            ],
        },
        { 
            id: 2, 
            title: 'Apartmán SEVER', 
            slug: 'apartmany-sever',
            text: `
                  Z panoramatického balkóna si môžete užiť čarovný výhľad na Vysoké Tatry. Apartmán SEVER s kapacitou 4 osoby 
                  je vybavený modernou, plne zariadenou kuchyňou (umývačka riadu, chladnička s mrazničkou, indukčná varná doska, 
                  rúra na pečenie, mikrovlnná rúra, kávovar Tchibo, kanvica), obývacou izbou a dvomi spálňami. Tiež disponuje 
                  súkromným WC, kúpeľnou a práčkou. Apartmán zahŕňa satelitnú TV s plochou obrazovkou a streamovacou službou NETFLIX, 
                  bezplatnú Wi-Fi, relaxačnú hojdačku a terasu z izby, vybavenú exteriérovým sedením.
                `,
            features: [
                {
                    icon: 'area',
                    text: '77 m2'
                },
                {
                    icon: 'wifi',
                    text: 'Wifi'
                },
                {
                    icon: 'parking',
                    text: 'Parkovanie'
                },
                {
                    icon: 'bed',
                    text: 'Veľká posteľ'
                },
                {
                    icon: 'door',
                    text: '2x izba'
                },
                {
                    icon: 'netflix',
                    text: 'Netflix'
                },
                {
                    icon: 'pracka',
                    text: 'Práčka'
                },
                {
                    icon: 'eye',
                    text: 'Výhľad na Vysoké Tatry'
                },
                {
                    icon: 'terrace',
                    text: 'Terasa'
                },
            ],
            mainImage: '/assets/images/sever/S1.jpg',
            secondaryImage: '/assets/images/sever/S2.jpg',
            images: [
                '/assets/images/sever/S3.jpg',
                '/assets/images/sever/S4.jpg',
                '/assets/images/sever/S5.jpg',
                '/assets/images/sever/S6.jpg',
                //'/assets/images/sever/S7.jpg',
                '/assets/images/sever/S8.jpg',
                '/assets/images/sever/S9.jpg',
                '/assets/images/sever/S10.jpg',
                '/assets/images/sever/S1.jpg',
                '/assets/images/sever/S2.jpg'
            ],
        }, 
    ],
    'en': [
        { 
            id: 1, 
            title: 'Apartment SOUTH', 
            slug: 'apartment-south',
            text: `
                  From the huge panoramic balcony, you can enjoy a wonderful view of the Kralova hola hill (Low Tatras). 
                  Apartment SOUTH for max. 6 people is equipped with a modern, fully equipped kitchen (dishwasher; refrigerator 
                  with freezer; induction hob; oven; microwave; coffee maker; kettle), living room, and two bedrooms. It also has 
                  a private toilet, bathroom and washing machine. The apartment includes a flat-screen satellite TV with streaming 
                  service NETFLIX, free Wi-Fi, a relaxing swinging chair, a terrace from the rooms, and a living room. There is 
                  also storage space for your skis, snowboards, bicycles and more.
                `,
            features: [
                {
                    icon: 'area',
                    text: '85 m2'
                },
                {
                    icon: 'wifi',
                    text: 'Wi-fi'
                },
                {
                    icon: 'parking',
                    text: 'Parking'
                },
                {
                    icon: 'bed',
                    text: 'King size bed'
                },
                {
                    icon: 'door',
                    text: 'Two bedrooms'
                },
                {
                    icon: 'netflix',
                    text: 'Netflix'
                },
                {
                    icon: 'pracka',
                    text: 'Washing machine'
                },
                {
                    icon: 'eye',
                    text: 'Low Tatras view'
                },
                {
                    icon: 'terrace',
                    text: 'Terrace'
                },
            ],
            mainImage: '/assets/images/juh/J1-min.jpg',
            secondaryImage: '/assets/images/juh/J2-min.jpg',
            images: [
                '/assets/images/juh/J3.jpg',
                '/assets/images/juh/J6.jpg',
                '/assets/images/juh/J4.jpg',
                '/assets/images/juh/J5.jpg',
                '/assets/images/juh/J7.jpg',
                '/assets/images/juh/J8.jpg',
                '/assets/images/juh/J1.jpg',
                '/assets/images/juh/J2.jpg'
            ],
        },
        { 
            id: 2, 
            title: 'Apartments NORTH', 
            slug: 'apartments-north',
            text: `
                  From the panoramic balcony you can enjoy a wonderful view of the High Tatras. Apartments NORTH for max. 
                  4 people are equipped with a modern, fully equipped kitchen (dishwasher; refrigerator with freezer; induction 
                  cooktop; oven; microwave; coffee maker; kettle), living room and two bedrooms. It also has a private toilet, 
                  bathroom and washing machine. The apartment includes flat-screen satellite TV with streaming service NETFLIX, 
                  free Wi-Fi, a relaxing swinging chair, and a terrace from the room. 
                `,
            features: [
                {
                    icon: 'area',
                    text: '77 m2'
                },
                {
                    icon: 'wifi',
                    text: 'Wi-fi'
                },
                {
                    icon: 'parking',
                    text: 'Parking'
                },
                {
                    icon: 'bed',
                    text: 'King size bed'
                },
                {
                    icon: 'door',
                    text: 'Two bedrooms'
                },
                {
                    icon: 'netflix',
                    text: 'Netflix'
                },
                {
                    icon: 'pracka',
                    text: 'Washing machine'
                },
                {
                    icon: 'eye',
                    text: 'High Tatras view'
                },
                {
                    icon: 'terrace',
                    text: 'Terrace'
                },
            ],
            mainImage: '/assets/images/sever/S1.jpg',
            secondaryImage: '/assets/images/sever/S2.jpg',
            images: [
                '/assets/images/sever/S3.jpg',
                '/assets/images/sever/S4.jpg',
                '/assets/images/sever/S5.jpg',
                '/assets/images/sever/S6.jpg',
                //'/assets/images/sever/S7.jpg',
                '/assets/images/sever/S8.jpg',
                '/assets/images/sever/S9.jpg',
                '/assets/images/sever/S10.jpg',
                '/assets/images/sever/S1.jpg',
                '/assets/images/sever/S2.jpg'
            ],
        }, 
    ]
}


export const PRIVACY = {
    'sk': [
        { 
            id: 1, 
            title: 'Zásady ochrany osobných údajov', 
            slug: 'zasady-ochrany-osobnych-udajov',
            text: `
                <p>Text</p>
                `,
        }
    ],
    'en': [
        { 
            id: 1, 
            title: 'Zásady ochrany osobných údajov', 
            slug: 'zasady-ochrany-osobnych-udajov',
            text: `
                <p>Text</p>
                `,
        }
    ]
}


export const TERMS = {
    'sk': [
        { 
            id: 1, 
            title: 'Všeobecné obchodné podmienky', 
            slug: 'vseobecne-obchodne-podmienky',
            text: `
                <p>Text</p>
                `,
        }
    ],
    'en': [
        { 
            id: 1, 
            title: 'Všeobecné obchodné podmienky', 
            slug: 'vseobecne-obchodne-podmienky',
            text: `
                <p>Text</p>
                `,
        }
    ]
}


export const RULES = {
    'sk': [
        { 
            id: 1, 
            title: 'Ubytovací poriadokv', 
            slug: 'ubytovaci-poriadok',
            text: `
                <p>Text</p>
                `,
        }
    ],
    'en': [
        { 
            id: 1, 
            title: 'Ubytovací poriadok', 
            slug: 'ubytovaci-poriadok',
            text: `
                <p>Text</p>
                `,
        }
    ]
}

export const SPECIALOFFER = {
    'sk': [
        { 
            id: 1, 
            title: 'Zážitková off-road jazda', 
            slug: 'zazitkova-off-road-jazda',
            intro: 'Extrémny terén v horách na špeciálne upravenom legendárnom vozidle JEEP Wrangler Rubicon',
            text: `
                <p>Objavte čaro prírody v ťažko prístupných horách v okolí Dobšinskej ľadovej jaskyne. Nevšedný a nezabudnuteľný zážitok poskytujúci nádherné scenérie a zákutia prírody v každom ročnom období.</p><br>
                <p>Výpravy sa môžu z bezpečnostných dôvodov zúčastniť maximálne 3 ľudia.</p><br>
                <p>V cene je zahrnutá jazda na 4 hodiny, teplý nápoj, káva alebo čaj. V prípade nepriaznivých poveternostných podmienok sa výprava neuskutoční. Bližšie informácie ako aj podmienky súvisiace s priebehom výpravy Vám radi poskytneme na vyžiadanie.</p><br>
                <p>250 EUR.</p>
                `,
            mainImage: '/assets/images/zazitkova-off-road-jazda/3.jpeg',
            secondaryImage: '/assets/images/zazitkova-off-road-jazda/2.jpeg',
            images: [
                '/assets/images/zazitkova-off-road-jazda/4.jpg',
                '/assets/images/zazitkova-off-road-jazda/2.jpeg',
                '/assets/images/zazitkova-off-road-jazda/1.jpg',
                '/assets/images/zazitkova-off-road-jazda/5.jpg',
                '/assets/images/zazitkova-off-road-jazda/6.jpg'
            ],
        }
    ],
    'en': [
        { 
            id: 1, 
            title: 'Ubytovací poriadok', 
            slug: 'ubytovaci-poriadok',
            text: `
                <p>Text</p>
                `,
        }
    ]
}
