
<div *ngIf="item">
	<div class="relative bg-gray-900 max-w-screen-2xl mx-auto">
		<div class="grid grid-cols-12 pt-5 relative z-10 max-w-screen-md mx-auto">
			<div class="col-span-12 px-8 md:px-16 pt-56 pb-56 md:pt-48 md:pb-48 fade-in-content">
				
			</div>
		</div>
		<div class="absolute w-full h-full top-0 z-0 overflow-hidden">
			<img class="h-full w-full object-cover section-cover" src="{{ item.image }}" alt="{{ item.title }}">
			<div class="absolute inset-0" style="mix-blend-mode: multiply;"></div>
		</div>
	</div>
	<div class="relative max-w-screen-md mx-auto py-10 article-detail">
		<div class="px-8 md:px-16">
			<div class="grid grid-cols-12 flex" photoGalleryGroup>
				<div class="mt-10 text-justify col-span-12">
					<div class="divider-style mb-5">
						<div class="short w-24 h-2 bg-white"></div>
						<div class="long w-full h-px bg-white"></div>
					</div>
					<h2 class="text-3xl font-extrabold tracking-tight text-white pb-16" [innerHtml]='item.title'>
						
					</h2>
				</div>
				<div class="text-justify col-span-12" [innerHtml]=" item.intro_text">
					
				</div>
				<div class="mt-10 text-justify col-span-12" *ngFor="let contentItem of mainContentObj">
					<div [innerHtml]="contentItem.content" *ngIf="contentItem.type === 'text'">

					</div>
					<img src="{{contentItem.content}}" *ngIf="contentItem.type === 'image'" alt="{{item.title}}" [photoGallery]="contentItem.content"/>

				</div>
				<div class="col-span-12">
					<div class="mt-10 mb-10 text-center col-span-12">
						<b>{{ "PRICE" | translate }}: {{ item.price }} €</b>
						<br><br>
						<a href="{{item.order_parameter}}" target="_blank" class="btn">{{ "ORDER-CTA" | translate }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--
	<div *ngIf="relatedBlogs" class="relative max-w-screen-md mx-auto">
		<div *ngIf="relatedBlogs.length" class="grid grid-cols-12 gap-5 md:gap-6 py-10 lg:gap-10">
			<div class="col-span-12">
				<div class="divider-style">
					<div class="short w-24 h-2 bg-white"></div>
					<div class="long w-full h-px bg-white"></div>
				</div>
		      	<div class="mt-8">
		        	<h2 class="text-3xl font-extrabold tracking-tight text-white">
						{{ "RELATED-BLOGS" | translate }}
		        	</h2>
		      	</div>
			</div>
			<div *ngFor="let blog of relatedBlogs; let i = index" class="col-span-12 md:col-span-6 lg:col-span-6 mb-12">
				<div class="relative">
					<h2 class="text-white absolute bottom-0 left-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-xl text-white">
		        		<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug" class="text-white">
							{{ blog.title }}
						</a>
		        	</h2>
					<div *ngIf="blog.blog_category" class="absolute top-0 right-0 bg-black bg-opacity-50 text-white z-10 px-5 py-3 text-md">
						{{ blog.blog_category.title }}
					</div>
					<a [routerLink]="('MENU-LINK-3' | translate) + '/'+ blog.slug">
						<img class="w-full relative object-cover z-0" src="{{ blog.image }}" alt="{{ blog.title }}">
					</a>
				</div>

		      	<div class="mt-6">
		        	
		        	<div class="text-justify" [innerHtml]=" blog.intro_text">
						
					</div>
		      	</div>
		    </div>
		</div>
	</div>
-->
</div>