import { Component, OnInit } from '@angular/core';
import { RouterModule, Routes, Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from './../../../services/env.service';
import { BlogService } from './../../../services/blog.service';
import { LangService } from './../../../services/lang.service';

@Component({
  	selector: 'app-blog-item',
  	templateUrl: './blog-item.component.html',
  	styleUrls: ['./blog-item.component.scss']
})
export class BlogItemComponent implements OnInit {

	blog: any;
	relatedBlogs = [];

	categories = [];
    selectedCategoryId = '';

    gallery: any;

    mainContentObj = [];

  	constructor(
  		private router: Router,
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
        private langService: LangService,
        private translate: TranslateService,
        private blogService: BlogService,
        private activatedRoute: ActivatedRoute,
  	) { }

  	ngOnInit(): void {
  		this.activatedRoute.params.subscribe(parameter => {
	      	this.translate.get('LANG-CODE').subscribe((lang: string) => {		
		      	this.getItem(parameter.slug, lang);
		      	this.getCategories();
		    });
	    });
	}
  		
  	getItem(slug,lang){
  		this.blogService.getItem(slug, lang).subscribe(
  			(res:any) => {
                this.blog = res.data[lang];
                this.findImg(this.blog.full_text);
                this.relatedBlogs = res.data[lang].related_blogs;
                //this.blog.gallery_items = JSON.parse(this.blog.gallery_items);
                this.titleService.setTitle(this.blog.title + ' - ' + this.env.siteNameConstant + this.env.siteNameSlogan);
		  		this.metaService.addTags([
		      		{ name: 'keywords', content: 'lawis apartments, apartmany lawis, ubytovanie poprad, vikendove apartmany, luxusne apartmany, apartmany poprad,' },
		      		{ name: 'description', content: 'Luxusné víkendové apartmány v Poprade' },
		    	]);
		    	this.langService.langUrl.sk = '/sk/blog/'+res.data['sk'].slug;
		    	this.langService.langUrl.en = '/en/blog/'+res.data['en'].slug;
            },
            err => {
                console.log(err);
            },
            () => {
            }
       	);
  	}

  	findImg(html) {
  		let self = this;
  		let img;
  		const sourceStr = html
		const searchStr = '<img';
		const indexes = [...sourceStr.matchAll(new RegExp(searchStr, 'gi'))].map(a => a.index);

		const searchImgEnd = '/>';
		const imgEndIndexes = [...sourceStr.matchAll(new RegExp(searchImgEnd, 'gi'))].map(a => a.index+2);

		const searchImgSrc = 'src';

		let lastIndex = 0;
		let rightOffset = 0;

		if(indexes.length){
			indexes.forEach((currIndex: any, index) => {
				imgEndIndexes.forEach((currEndIndex: any) => {

				});
				this.mainContentObj.push({
					type: 'text',
					content: html.substring(lastIndex, currIndex)
				});
				let imageObject = html.substring(currIndex, imgEndIndexes[index]);
				let imageSrc = imageObject.substring(imageObject.indexOf("src")+5, imageObject.indexOf("alt")-2);
				this.mainContentObj.push({
					type: 'image',
					content: imageSrc
				});
				lastIndex = imgEndIndexes[index];

				if(index === indexes.length-1){
					this.mainContentObj.push({
						type: 'text',
						content: html.substring(imgEndIndexes[index], html.length)
					});
				}

			});
		}else {
			
			this.mainContentObj.push({
				type: 'text',
				content: sourceStr
			});
			
		}
	}

  	getCategories(){
  		this.translate.get('LANG-CODE').subscribe((lang: string) => {		
	  		this.blogService.getCategoriesList(lang).subscribe(
	            (res:any) => {
	                //console.log(res.data);
	                this.categories = res.data;
	            },
	            err => {
	                console.log(err);
	            },
	            () => {
	            }
	        );
        });
  	}

  	openCategory(categoryId){
  		this.translate.get('MENU-LINK-3').subscribe((res: string) => {
  			//console.log(res+'?category='+categoryId);
  			let link = res+'?category='+categoryId;
  			//this.router.navigate([link]);
  			this.router.navigate([res], {
        	queryParams: { category: categoryId }
		});
  		});
  		
  	}

}
