import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { EnvService } from './../../services/env.service';
import { LangService } from './../../services/lang.service';
import { BlogService } from './../../services/blog.service';

import { APARTMENTS } from './../../mock-data/mock-data';
import { SPECIALOFFER } from './../../mock-data/mock-data';

@Component({
  	selector: 'app-home-page',
  	templateUrl: './home-page.component.html',
  	styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

    //blogs = BLOGS;
    apartments;
    blogs;
    specialoffer;

  	constructor(
  		private titleService: Title, 
        private metaService: Meta,
        private env: EnvService,
        private langService: LangService,
        private translate: TranslateService,
        private blogService: BlogService,
  	) {
  		// this language will be used as a fallback when a translation isn't found in the current language
        //this.translate.setDefaultLang('sk');

         // the lang to use, if the lang isn't available, it will use the current loader to get them
        //this.translate.use('sk');


        
  	}

  	ngOnInit(): void {
        //console.log(this.env.activeLocale);
        this.apartments = APARTMENTS[this.env.activeLocale];
        this.specialoffer = SPECIALOFFER[this.env.activeLocale];
        this.translate.get('SITE-TITLE').subscribe((text: string) => {
  		    this.titleService.setTitle(this.env.siteNameConstant + ' | ' + text);
        });
  		this.metaService.addTags([
      		{ name: 'keywords', content: 'lawis apartments, apartmany lawis, ubytovanie poprad, vikendove apartmany, luxusne apartmany, apartmany poprad,' },
      		{ name: 'description', content: 'Moderné apartmány v&nbsp;Poprade. Ideálne miesto nie len pre vašu dovolenku' },
    	]);
    	this.langService.langUrl.sk = '/sk';
    	this.langService.langUrl.en = '/en';

        this.getItems();
  	}

    getItems(){
        this.translate.get('LANG-CODE').subscribe((lang: string) => {        
            this.blogService.getItemsList(lang, '', 6).subscribe(
                (res:any) => {
                    //console.log(res.data);
                    this.blogs = res.data.articles.slice(0,6);
                },
                err => {
                    console.log(err);
                },
                () => {
                }
            );
        });
    }

}
