<div class="rounded-md bg-white p-4 py-6 fixed z-50 w-full top-0 shadow" *ngIf="display" [ngClass]="{'bg-green-300': id=='success', 'bg-red-300': id=='error'}">
	<div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
	  	<div class="flex">
	    	<div class="flex-shrink-0">
	      		<!-- Heroicon name: solid/check-circle -->
	      		<svg *ngIf="id=='success'" class="h-5 w-5 text-green-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
	        		<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
	      		</svg>

	      		<svg *ngIf="id=='error'" class="h-5 w-5 text-red-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
  					<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
	      		</svg>
	    	</div>
	    	<div class="ml-3">
	      		<p class="text-sm font-medium text-gray-800" [ngClass]="{'text-green-800': id=='success', 'text-red-800': id=='error'}">
	        		{{ message }}
	      		</p>
	    	</div>
	    	<div class="ml-auto pl-3">
	      		<div class="-mx-1.5 -my-1.5">
	        		<button type="button" class="inline-flex bg-gray-50 rounded-md p-1.5 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
	          			<span class="sr-only">Dismiss</span>
	          			<!-- Heroicon name: solid/x -->
	          			<svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
	            			<path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
	          			</svg>
	        		</button>
	      		</div>
	    	</div>
	  	</div>
	</div>
</div>